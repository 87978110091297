import { Link } from "react-router-dom";
import { useEffect } from "react";
import {useDispatch, useSelector} from  'react-redux'
import backaero from '../../img/arrow-left-circle-fill.svg'
import { Carousal } from "../Common/Carousal"
import { getUserMenuDetailsById } from "../../Features/Slices/authSlice"
import{ getBrokerDetailById} from "../../Features/Slices/brokSlice"
import profile1 from '../../img/sampleBroker1.jpg'
import sampleBroker from '../../img/sampleBroker1.jpg'
import contactUs from '../../img/broker_svg_contact.svg';
import "../../scss/broker.scss"

export function BrokerHome() {


    const dispatch = useDispatch()
    const brokerInfo =
    useSelector(
        (state) => state.brok
    )


useEffect(() => {
      dispatch(getBrokerDetailById())
     //console.log(brokerDetails)
}, [])

useEffect(() => {

    
   dispatch(getUserMenuDetailsById())
   
 }, [])


    return (<>


<div className="row">
   <div className="col-md-12" style={{ fontWeight: 'bold' }}>My Profile</div>
</div>

<div className="row mb-4"></div>

<div className="row">
   <div className="col-md-4">
   <img 
                src={sampleBroker} 
                alt="Descriptive Alt Text" 
                className="img-fluid rounded-circle"  // Use rounded-circle for circular shape
                style={{ width: '200px', height: '200px' }} // Set width and height to be the same for a circle
            />
   </div>
   <div className="col-md-8">
      <div className="row">
      <div className="col-md-4">
    <label className="col-md-4 col-form-label">Name</label>
    <label className="form-control-plaintext" style={{ fontWeight: 'bold'}}>{brokerInfo.brokerDetails.name}</label>
</div>
         <div className="col-md-4">
         <label className="col-md-4 col-form-label" style={{ whiteSpace: 'nowrap' }}>Phone Number</label>
         <label style={{ fontWeight: 'bold'}} className="form-control-plaintext"> {brokerInfo.brokerDetails.phoneNumber}</label>
         </div>
         <div className="col-md-4">

         <label className="col-md-4 col-form-label">Email</label>
         <label style={{ fontWeight: 'bold'}} className="form-control-plaintext">{brokerInfo.brokeremail}</label>
         </div>
      </div>
      <div className="row">
      <div className="col-md-4">
      <label   className="col-md-4 col-form-label" style={{ whiteSpace: 'nowrap' }}>Matrimony name</label>
      <label  style={{ fontWeight: 'bold' }} className="form-control-plaintext">{brokerInfo.brokerDetails.description}</label>
      </div>
      <div className="col-md-8">
      <label   className="col-md-4 col-form-label">Address</label>
      <label style={{ fontWeight: 'bold' }} className="form-control-plaintext">{brokerInfo.brokerDetails.address1} {brokerInfo.brokerDetails.address2}</label>
      </div>
      </div>

   </div>
</div>
<div className="row mb-4"></div>
<div className="row">
<div className="col-md-4"></div>
<div className="col-md-8">
   
   <div className="row">
   <div className="col-2">
      </div>
      <div className="col-8">
      <div> <br /><br /><br /></div>
      <div className="app-container">
   
      <img src={contactUs} alt="Example" className="responsive-image" />
      </div>

      <div> <br /><br /></div>
      </div>
   </div>

   <div className="col-2">
   </div>

   <div className="row">
      <div className="col-12">
      <label style={{ fontWeight: '400', fontStyle: 'italic'}}>***Please call us at 8667393715 to inform us if the displayed details are incorrect. We will help you with the update***</label>
      </div>
      <br /><br /><br /><br />
   </div>

</div>
</div>

  
   <div className="content1">


{/* <p className="h4">My Details</p> */}

 {/* { (brokerInfo && brokerInfo.brokerDetails )?
 (

<div id="dvpublicProfile" className="row">
   <div className="col-md-8">
  
<div className="row">
<label  style={{ fontWeight: 'bold' }} className="col-4 col-form-label">Name</label>
<div className="col-8">
 
   <label  className="form-control-plaintext">: {brokerInfo.brokerDetails.name}</label>
</div>
</div>
<div className="row">
<label  style={{ fontWeight: 'bold' }} className="col-4 col-form-label">Phone Number</label>
<div className="col-8">
<label  className="form-control-plaintext">: {brokerInfo.brokerDetails.phoneNumber}</label>
</div>
</div>

<div className="row">
<label  style={{ fontWeight: 'bold' }} className="col-4 col-form-label">Matrimony name</label>
<div className="col-8">
<label  className="form-control-plaintext">: {brokerInfo.brokerDetails.description}</label>
</div>
</div>

<div className="row">
<label  style={{ fontWeight: 'bold' }} className="col-4 col-form-label">Address1</label>
<div className="col-8">
<label  className="form-control-plaintext">: {brokerInfo.brokerDetails.address1}</label>
</div>
</div>



<div className="row">
<label  style={{ fontWeight: 'bold' }} className="col-4 col-form-label">Address2</label>
<div className="col-8">
<label  className="form-control-plaintext">: {brokerInfo.brokerDetails.address2}</label>
</div>
</div>

<div className="row">
<label  style={{ fontWeight: 'bold' }} className="col-4 col-form-label">State</label>
<div className="col-8">
<label  className="form-control-plaintext">: {brokerInfo.brokerDetails.state}</label>
</div>
</div>

<div className="row">
<label  style={{ fontWeight: 'bold' }} className="col-4 col-form-label">Pincode</label>
<div className="col-8">
<label  className="form-control-plaintext">: {brokerInfo.brokerDetails.pincode}</label>
</div>
</div>

</div>
         
  


   <div className="col-md-4">
   {
   
     <div>
        
       <Carousal  imageUrls={ [brokerInfo.brokerImageUrl]} ></Carousal> 
     </div>
  }
     </div>

 </div>
 
 ):null
 
 } */}

 
 <div>
   </div> 

   
   </div>


    </>)
}
