import authAxious from '../Config/interceptor'
//import axios from 'axios'

const API_URL_GET_BROKERS ='/broker/getAllBroker'
//const API_URL_FETCH_BROKERS ='http://localhost:5000/api/broker/getAllBroker1'
const API_URL_FETCH_BROKERS ='/broker/getAllBroker'
const API_URL_REGISTER_BROKER ='/broker'
const API_URL_BROKER_DETAILS_BY_ID ='/broker/getBrokerDetailById'

// const authAxious = axios.create({
//     baseURL:'http://localhost:5000/api',
//     headers:{
//         Authorization:`Bearer ${sessionStorage.getItem("Authorization-key")}`
//     },
// })

const getAllBrokers = async() =>{
    const response = await authAxious.post(API_URL_GET_BROKERS)
    return response.data;
}

// const fetchAllBrokers = async() =>{
//     const response = await authAxious.get(API_URL_FETCH_BROKERS)
//     return response.data;
// }

const fetchAllBrokers = async(data) =>{
    const response = await authAxious.post(API_URL_FETCH_BROKERS,data)
    return response.data;
}

const registerBroker = async(data) =>{
    const response = await authAxious.post(API_URL_REGISTER_BROKER,{data})
    return response.data;
}

const getBrokerDetailById = async(data) =>{
    const response = await authAxious.post(API_URL_BROKER_DETAILS_BY_ID,{"brokerId":data})
    return response.data;
}

const brokService ={
    getAllBrokers,
    fetchAllBrokers,
    registerBroker,
    getBrokerDetailById
    
}

export default brokService 
