import { Carousal } from "../Common/Carousal"
import "../../scss/publicProfile.scss"
import { useEffect, useState } from "react";
import { deleteProfile,resetdeleteProfile,getProfileDetailsById, getbrokerDetailsById,getProfileImageUrl, reset } from "../../Features/Slices/profSlice"
import sessionData from "../../sessionData";
import { useDispatch, useSelector } from 'react-redux'
import { replace, useNavigate } from 'react-router-dom'
import { useLocation,useSearchParams } from 'react-router-dom'
import { Link } from "react-router-dom";
import { toast } from "react-toastify"
import backaero from '../../img/arrow-left-circle-fill.svg'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
//importing pdfmake to generate our PDF file
import pdfMake from "pdfmake/build/pdfmake"
//importing the fonts whichever present inside vfs_fonts file
import pdfFonts from "pdfmake/build/vfs_fonts"
//importing the encoded font file into our project
import tamilFont from './TamilFontBase64'
import "../../scss/profileList.scss"
// import "./pdfmake.scss"
//Making use of all the fonts defined
pdfMake.vfs = pdfFonts.pdfMake.vfs
//Adding our own font into the vfs
window.pdfMake.vfs["TiroTamil-Regular.ttf"] = tamilFont


export function PublicProfile() {

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const search = useLocation().search
 
  const [searchParams] = useSearchParams();
  console.log(searchParams);
  const profileId = searchParams.get('id')
  const brokerId = searchParams.get('brId')
  var rasiNames = []
  var amsamNames = []



  const profile =
      useSelector(
          (state) => state.prof
      )
      const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  useEffect(() => {
      
        dispatch(getProfileDetailsById(profileId))
       console.log(profile.profileDetails)
  }, [])

  useEffect(() => {
     dispatch(getbrokerDetailsById(brokerId))
     console.log(profile.brokerDetails)
    
}, [])

useEffect(() => {
  
  if (profile.isdeleteProfileSuccess && profile.messagedeleteProfile != undefined && profile.messagedeleteProfile) {
    toast.success(profile.messagedeleteProfile)
    dispatch(resetdeleteProfile())
    //dispatch(getProfileImageUrl({ "brokerId": null, "profileId": profileId }))
  }
  else if (profile.isdeleteProfileError == true) {
    toast.error("Network Error!!!")
    dispatch(resetdeleteProfile())
  }
}, [profile.isdeleteProfileSuccess,profile.messagedeleteProfile,profile.isdeleteProfileError]);


useEffect(() => {
  dispatch(getProfileImageUrl({"brokerId":brokerId,"profileId":profileId}))
 
}, [])

const [formData,setFormData] = useState({
  deleteName:''
})

let deleteName ="";

  const onProfileBackbuttonClick = (e) => {
    e.preventdefault()
   // navigate('/ProfileList?id='+ brokerId);
   navigate('/BrokerList')
}

const [show, setShow] = useState(false);
const [hasError, setHasError] = useState(false);

const handleClose = () => setShow(false);
const handleShow = () => setShow(true);

const onDeleteNamechange = (e) => {
  if (e.target.value != profile.profileDetails.name) {
    setHasError(true)
  }
  else {
    setHasError(false)
  }
}

const onDeleteClick = (e) => {
  if (document.getElementById("deleteName").value != profile.profileDetails.name) {
    setHasError(true)
    handleShow()
  }
  else {
    handleClose()
    const data = {"profileId": profileId }
    dispatch(deleteProfile(data))
    setIsFormSubmitted(true);
    debugger
    

    setTimeout(function() {
      navigate('/ProfileList', { replace: true });
    }, 5000);
   
  }
}

function onPrintClick()
  {
     rasiNames = [
      profile.profileDetails.horoScope.meenaR, profile.profileDetails.horoScope.meshaR,profile.profileDetails.horoScope.vrishbaR, profile.profileDetails.horoScope.mithunaR,
      profile.profileDetails.horoScope.karkataR, profile.profileDetails.horoScope.simhaR,profile.profileDetails.horoScope.kanyaR, profile.profileDetails.horoScope.tulaR,
      profile.profileDetails.horoScope.vrishikaR,profile.profileDetails.horoScope.dhanuR,profile.profileDetails.horoScope.makaraR, profile.profileDetails.horoScope.khumbhaR
    ];
    amsamNames = [
      profile.profileDetails.horoScope.meenaA, profile.profileDetails.horoScope.meshaA,profile.profileDetails.horoScope.vrishbaA, profile.profileDetails.horoScope.mithunaA,
      profile.profileDetails.horoScope.karkataA, profile.profileDetails.horoScope.simhaA,profile.profileDetails.horoScope.kanyaA, profile.profileDetails.horoScope.tulaA,
      profile.profileDetails.horoScope.vrishikaA,profile.profileDetails.horoScope.dhanuA,profile.profileDetails.horoScope.makaraA, profile.profileDetails.horoScope.khumbhaA
    ];
    const content = [
      [{ text: rasiNames[0], alignment: 'center', bold: true, margin: [0, 20] }, { text: rasiNames[1], alignment: 'center', bold: true, margin: [0, 20] }, { text: rasiNames[2], alignment: 'center', bold: true, margin: [0, 20] }, { text: rasiNames[3], alignment: 'center', bold: true, margin: [0, 20] }],
      [{ text: rasiNames[11], alignment: 'center', bold: true, margin: [0, 20] }, { text: 'ராசி', alignment: 'center', bold: true, margin: [0, 40], colSpan: 2, rowSpan: 2 }, {}, { text: rasiNames[4], alignment: 'center', bold: true, margin: [0, 20] }],
      [{ text: rasiNames[10], alignment: 'center', bold: true, margin: [0, 20] }, {}, {}, { text: rasiNames[5], alignment: 'center', bold: true, margin: [0, 20] }],
      [{ text: rasiNames[9], alignment: 'center', bold: true, margin: [0, 20] }, { text: rasiNames[8], alignment: 'center', bold: true, margin: [0, 20] }, { text: rasiNames[7], alignment: 'center', bold: true, margin: [0, 20] }, { text: rasiNames[6], alignment: 'center', bold: true, margin: [0, 20] }]
    ];
    
    const content1 = [
      [{ text: amsamNames[0], alignment: 'center', bold: true, margin: [0, 20] }, { text: amsamNames[1], alignment: 'center', bold: true, margin: [0, 20] }, { text: amsamNames[2], alignment: 'center', bold: true, margin: [0, 20] }, { text: amsamNames[3], alignment: 'center', bold: true, margin: [0, 20] }],
      [{ text: amsamNames[11], alignment: 'center', bold: true, margin: [0, 20] }, { text: 'அம்சம்', alignment: 'center', bold: true, margin: [0, 40], colSpan: 2, rowSpan: 2 }, {}, { text: amsamNames[4], alignment: 'center', bold: true, margin: [0, 20] }],
      [{ text: amsamNames[10], alignment: 'center', bold: true, margin: [0, 20] }, {}, {}, { text: amsamNames[5], alignment: 'center', bold: true, margin: [0, 20] }],
      [{ text: amsamNames[9], alignment: 'center', bold: true, margin: [0, 20] }, { text: amsamNames[8], alignment: 'center', bold: true, margin: [0, 20] }, { text: amsamNames[7], alignment: 'center', bold: true, margin: [0, 20] }, { text: amsamNames[6], alignment: 'center', bold: true, margin: [0, 20] }]
    ];

    pdfMake.fonts={
      TiroTamilRegular: {
          normal: 'TiroTamil-Regular.ttf',
          bold: 'TiroTamil-Regular.ttf',
          italics: 'TiroTamil-Regular.ttf',
          bolditalics: 'TiroTamil-Regular.ttf'
      }
      
    }
    var docDefinition = {
      content: [
        {
          text: profile.brokerDetails.description,
          style: 'header',
          alignment: 'center',
        },
        {
          text: 'Broker Detail',
          style: 'subheader',
        },
        {
          columns: [
            {
              width: '50%',
              text: [
                { text: 'Name: ', bold: true }, profile.brokerDetails.name+'\n',
                { text: 'Phone: ', bold: true }, profile.brokerDetails.phoneNumber+'\n',
              ],
            },
            {
              width: '50%',
              text: [
                { text: 'Address: ', bold: true }, profile.brokerDetails.address1 + ',' + profile.brokerDetails.address2 +'\n',
                { text: 'Email: ', bold: true }, 'mmm.doe@gmail.com\n\n',
              ],
            },
          ],
        },
        {
          text: 'Profile Details',
          style: 'subheader',
        },
      

      {
        columns: [
          {
            width: '25%',
            stack: [
              { text: 'Name: ', bold: true, margin: [0, 0, 0, 8] }, // Added margin bottom
              { text: 'Qualification:', margin: [0, 0, 0, 8] }, // Added margin bottom
              { text: 'Job: ', bold: true, margin: [0, 0, 0, 8] },
              { text: 'Salary per month', margin: [0, 0, 0, 8] },
              { text: 'Date of birth', margin: [0, 0, 0, 8] },
              { text: 'Star: ', bold: true, margin: [0, 0, 0, 8] },
              { text: 'Rasi', margin: [0, 0, 0, 8] },
              { text: 'Religion', margin: [0, 0, 0, 8] },
              { text: 'Caste', margin: [0, 0, 0, 8] },
              { text: 'Sister(s) Unmarried', margin: [0, 0, 0, 8] },
              { text: 'Sister(s) Married', margin: [0, 0, 0, 8] },
              { text: 'Brother(s) Unmarried', margin: [0, 0, 0, 8] },
              { text: 'Brother(s) Married', margin: [0, 0, 0, 8] }
            ],
          },
          {
            width: '5%',
            stack: [ { text: ':', bold: true, margin: [0, 0, 0, 8] }, // Added margin bottom
              { text: ':', margin: [0, 0, 0, 8] }, // Added margin bottom
              { text: ':', bold: true, margin: [0, 0, 0, 8] },
              { text: ':', margin: [0, 0, 0, 8] },
              { text: ':', bold: true, margin: [0, 0, 0, 8] },
              { text: ':', margin: [0, 0, 0, 8] },
              { text: ':', margin: [0, 0, 0, 8] },
              { text: ':', margin: [0, 0, 0, 8] },
              { text: ':', margin: [0, 0, 0, 8] },
              { text: ':', margin: [0, 0, 0, 8] },
              { text: ':', margin: [0, 0, 0, 8] },
              { text: ':', margin: [0, 0, 0, 8] },
              { text: ':', margin: [0, 0, 0, 8] }
            ]
          },
          {
            width: '70%',
            stack: [
              { text: profile.profileDetails.name, bold: true, margin: [0, 0, 0, 8] },
              { text: profile.profileDetails.qualification, margin: [0, 0, 0, 8] },
              { text: profile.profileDetails.job, bold: true, margin: [0, 0, 0, 8] },
              { text: profile.profileDetails.salary, margin: [0, 0, 0, 8] },
              { text: profile.profileDetails.DOB, bold: true, margin: [0, 0, 0, 8] },
              { text: profile.profileDetails.star, bold: true, margin: [0, 0, 0, 8] },
              { text: profile.profileDetails.rasi, margin: [0, 0, 0, 8] },
              { text: profile.profileDetails.religion, margin: [0, 0, 0, 8] },
              { text: profile.profileDetails.caste, margin: [0, 0, 0, 8] },
              { text: profile.profileDetails.sistersUnmarried, margin: [0, 0, 0, 8] },
              { text: profile.profileDetails.sistersMarried, margin: [0, 0, 0, 8] },
              { text: profile.profileDetails.brothersUnmarried, margin: [0, 0, 0, 8] },
              { text: profile.profileDetails.brothersMarried, margin: [0, 0, 0, 8] },
            ],
          },
        ],
      },  
      {

        columns:[
          {
            width:'50%',
        style: 'table',
        table: {
          widths: [50, 50, 50, 50],
          body: content
        },
        layout: {
          hLineColor: () => '#000000',
          vLineColor: () => '#000000',
          hLineWidth: () => 1,
          vLineWidth: () => 1
        }
      },
      {
        width:'50%',
    style: 'table',
    table: {
      widths: [50, 50, 50, 50],
      body: content1
    },
    layout: {
      hLineColor: () => '#000000',
      vLineColor: () => '#000000',
      hLineWidth: () => 1,
      vLineWidth: () => 1
    }
  }
      ]

      },

      {
        columns: [
          {
            width: '20%',
            stack: [
              { text: 'திசை இருப்பு : ', bold: true, margin: [0, 0, 0, 8] }, // Added margin bottom
            
            ],
          },
          {
            width: '20%',
            stack: [ { text: profile.profileDetails.horoScope.dhasa, bold: true, margin: [0, 0, 0, 8] }, // Added margin bottom
              
            ]
          },
          {
            width: '3%',
            stack: [
              { text:  profile.profileDetails.horoScope.year, bold: true, margin: [0, 0, 0, 8] },
             
            ],
          },
          {
            width: '15%',
            stack: [
              { text: 'வருடம்', bold: true, margin: [0, 0, 0, 8] },
             
            ],
          },
          {
            width: '3%',
            stack: [
              { text: profile.profileDetails.horoScope.month, bold: true, margin: [0, 0, 0, 8] },
             
            ],
          },
          {
            width: '10%',
            stack: [
              { text: 'மாதம்', bold: true, margin: [0, 0, 0, 8] },
             
            ],
          },
          {
            width: '3%',
            stack: [
              { text:  profile.profileDetails.horoScope.day, bold: true, margin: [0, 0, 0, 8] },
             
            ],
          },
          {
            width: '10%',
            stack: [
              { text: 'நாள்', bold: true, margin: [0, 0, 0, 8] },
             
            ],
          },
        ],
      }, 

      ],
      styles: {
        header: {
          fontSize: 22,
          bold: true,
          margin: [0, 0, 0, 10],
        },
        subheader: {
          fontSize: 16,
          bold: true,
          margin: [0, 10, 0, 5],
        },
        tableHeader: {
          bold: true,
          fontSize: 13,
          color: 'black',
        },
        table: {
          margin: [0, 5, 0, 15]
        }
      },
      defaultStyle: {
        font: 'TiroTamilRegular',
      },
    };
      let fileName = profile.profileDetails.name + "_Biodata.pdf"
      pdfMake.createPdf(docDefinition).download(fileName);
  }
const onCancelClick = (e) => {
  navigate(backuButtonUrl,{replace:true})
}




const backuButtonUrl ='/ProfileList?'
const backEditProgile ='/EditProfile?id='+ profileId
const backEditHoroscope ='/AddProfileImage?id='+ profileId
  return (
    <>

{profile.isProfileDetailsByIdLoading && <div className="d-flex flex-column justify-content-center align-items-center"><div className="spinner-border text-info" style={{width: '3rem', height: '3rem'}}></div><div>Loading...</div></div>}

    <div>

     <Link to={backuButtonUrl} className="dropdown-item d-flex align-items-center">
     <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#1aa179" className="bi bi-arrow-left-circle" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5z"/>
</svg><p className="h6 mb-0 ms-2">Go Back</p></Link>

<div>
    <br />
</div>
          

   
    <Link to={backEditProgile} className="dropdown-item">
    <p className="h4">Profile Details
       <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-pencil-square" viewBox="0 0 16 16">
  <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
  <path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"/>
</svg>
</p>
</Link>



    
    
      <div id="dvpublicProfile" className="row">
        <div className="col-md-8">
       
  <div className="row">
    <label  style={{ fontWeight: 'bold' }} className="col-4 col-form-label">Name</label>
    <div className="col-8">
      
        <label  className="form-control-plaintext">: {profile.profileDetails.name}</label>
    </div>
  </div>
  <div className="row">
    <label  style={{ fontWeight: 'bold' }} className="col-4 col-form-label">Qualification</label>
    <div className="col-8">
    <label  className="form-control-plaintext">: {profile.profileDetails.qualification}</label>
    </div>
  </div>

  <div className="row">
    <label  style={{ fontWeight: 'bold' }} className="col-4 col-form-label">Job</label>
    <div className="col-8">
    <label  className="form-control-plaintext">: {profile.profileDetails.job}</label>
    </div>
  </div>

  <div className="row">
    <label  style={{ fontWeight: 'bold' }} className="col-4 col-form-label">Salary per month</label>
    <div className="col-8">
    <label  className="form-control-plaintext">: {profile.profileDetails.salary}</label>
    </div>
  </div>

  
  <div className="row">
    <label  style={{ fontWeight: 'bold' }} className="col-4 col-form-label">Father occu.</label>
    <div className="col-8">
    <label  className="form-control-plaintext">: {profile.profileDetails.fatherOccupation}</label>
    </div>
  </div>
  <div className="row">
    <label  style={{ fontWeight: 'bold' }} className="col-4 col-form-label">Mother occ.</label>
    <div className="col-8">
    <label  className="form-control-plaintext">: {profile.profileDetails.motherOccupation}</label>
    </div>
  </div>
  <div className="row">
    <label  style={{ fontWeight: 'bold' }} className="col-4 col-form-label">Religion</label>
    <div className="col-8">
    <label  className="form-control-plaintext">: {profile.profileDetails.religion}</label>
    </div>
  </div>

  <div className="row">
    <label  style={{ fontWeight: 'bold' }} className="col-4 col-form-label">Caste</label>
    <div className="col-8">
    <label  className="form-control-plaintext">: {profile.profileDetails.cast}</label>
    </div>
  </div>

  <div className="row">
    <label  style={{ fontWeight: 'bold' }} className="col-4 col-form-label">District</label>
    <div className="col-8">
    <label  className="form-control-plaintext">: {profile.profileDetails.district}</label>
    </div>
  </div>

  <div className="row">
    <label  style={{ fontWeight: 'bold' }} className="col-4 col-form-label">State</label>
    <div className="col-8">
    <label  className="form-control-plaintext">: {profile.profileDetails.state}</label>
    </div>
  </div>

  <div className="row">
    <label  style={{ fontWeight: 'bold' }} className="col-4 col-form-label">Status</label>
    <div className="col-8">
    <label  className="form-control-plaintext">: {profile.profileDetails.status}</label>
    </div>
  </div>

            {/* <div className="row"> */}

          

           
               
                {/* <input type="text" readOnly={true}  className="form-control"  onChange={onchange} id="name"></input> */}
              </div>
              
       


        <div className="col-md-4">
        {
        profile && profile.Images?
          (
          <div>
          <Carousal imageUrls={ profile?profile.Images:null} ></Carousal>
          </div>):  (<div> No photo available </div>)
       }
          </div>

      </div>
      <br></br>
      <Link to={backEditHoroscope} className="dropdown-item">

      <br/><br/>

    <p className="h4">Horoscope Details
    <span> <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-pencil-square" viewBox="0 0 16 16">
  <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
  <path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"/>
</svg></span>
    </p></Link>

    <div id="dvhoroscope" className="row">

    <div className="col-md-4">
    {/* <div className="row"> */}

  

  <div className="row">
    <label  style={{ fontWeight: 'bold' }} className="col-4 col-form-label">Date of Birth</label>
    <div className="col-8">
    <label  className="form-control-plaintext">: {profile.profileDetails.DOB}</label>
    </div>
  </div>

  <div className="row">
    <label  style={{ fontWeight: 'bold' }} className="col-4 col-form-label">Birth Time</label>
    <div className="col-8">
    <label  className="form-control-plaintext">: {profile.profileDetails.birthTime}</label>
    </div>
  </div>

  <div className="row">
    <label  style={{ fontWeight: 'bold' }} className="col-4 col-form-label">Place of Birth</label>
    <div className="col-8">
    <label  className="form-control-plaintext">: *****</label>
    </div>
  </div>

  <div className="row">
    <label  style={{ fontWeight: 'bold' }} className="col-4 col-form-label">Star</label>
    <div className="col-8">
    <label  className="form-control-plaintext">: {profile.profileDetails.star}</label>
    </div>
  </div>

  <div className="row">
    <label  style={{ fontWeight: 'bold' }} className="col-4 col-form-label">Rasi</label>
    <div className="col-8">
    <label  className="form-control-plaintext">: {profile.profileDetails.rasi}</label>
    </div>
  </div>

  <br/><br/>

{/* <div className="form-group col-12">
  <label htmlFor="dob">Birth</label>
  <input type="text" readOnly={true} className="form-control" value={profile.profileDetails.DOB} id="dob"></input>
</div> */}

</div>

{/* <div className="row">
<div className="form-group col-12">
  <label htmlFor="time">Time</label>
  <input type="text" readOnly={true} className="form-control" value="12:35 PM" id="time"></input>
</div>
</div>

<div className="row">
<div className="form-group col-12">
  <label htmlFor="pob">Place of Birth</label>
  <input type="text" readOnly={true} className="form-control" value="Nagercoil" id="pob"></input>
</div>
</div>
<div className="row">
<div className="form-group col-12">
  <label htmlFor="dist">District</label>
  <input type="text" readOnly={true} className="form-control" value="Kanyakumari" id="dist"></input>
</div>
</div>

<div className="row">
<div className="form-group col-12">
  <label htmlFor="state">State</label>
  <input type="text" readOnly={true} className="form-control" value="Tamil nadu" id="state"></input>
</div>
</div>

<div className="row">
<div className="form-group col-12">
  <label htmlFor="star">Star</label>
  <input type="text" readOnly={true} className="form-control" value="Swathi" id="star"></input>
</div>
</div>

<div className="row">
<div className="form-group col-12">
  <label htmlFor="rasi">Rasi</label>
  <input type="text" readOnly={true} className="form-control" value="Thulam" id="rasi"></input>
</div>
</div> */}

    {/* </div> */}



    <div className="col-md-8">
      <div className="row">
      <div className="row">
          <div className="col-3" style={{ borderWidth: '1.5px', borderStyle: 'solid', borderColor: '#1aa179' }}>
           {
           profile?.profileDetails?.horoScope?.meenaR?.split(' ').map((item, index) => (
            <span style={{ color: item === 'ல' ? 'red' : 'black' }} key={index}>
            {item === ' ' ? '\u00A0' : item} {/* Handle spaces as well */}
            </span>
      ))}
            </div>
          <div className="col-3" style={{ borderWidth: '1.5px', borderStyle: 'solid', borderColor: '#1aa179' }}>
{profile?.profileDetails?.horoScope?.meshaR?.split(' ').map((item, index) => (

          <span style={{ color: item === 'ல' ? 'red' : 'black' }} key={index}>
            {item === ' ' ? '\u00A0' : item} {/* Handle spaces as well */}
            </span>
      ))}
    

             </div>

          <div className="col-3" style={{ borderWidth: '1.5px', borderStyle: 'solid', borderColor: '#1aa179' }}>
          
          {profile?.profileDetails?.horoScope?.vrishbaR?.split(' ').map((item, index) => (
           <span style={{ color: item === 'ல' ? 'red' : 'black' }} key={index}>
           {item === ' ' ? '\u00A0' : item} {/* Handle spaces as well */}
           </span>
      ))}
    
            
            </div>
          <div className="col-3" style={{ borderWidth: '1.5px', borderStyle: 'solid', borderColor: '#1aa179' }}>
          {profile?.profileDetails?.horoScope?.mithunaR?.split(' ').map((item, index) => (
           <span style={{ color: item === 'ல' ? 'red' : 'black' }} key={index}>
           {item === ' ' ? '\u00A0' : item} {/* Handle spaces as well */}
           </span>
      ))}
            
            </div>
      </div>
      <div className="row">
          <div className="col-3" style={{ borderWidth: '1.5px', borderStyle: 'solid', borderColor: '#1aa179' }}>
            {profile?.profileDetails?.horoScope?.khumbhaR?.split(' ').map((item, index) => (
         <span style={{ color: item === 'ல' ? 'red' : 'black' }} key={index}>
         {item === ' ' ? '\u00A0' : item} {/* Handle spaces as well */}
         </span>
      ))}
          </div>
          <div className="col-6 text-center">ராசி</div>
          
          <div className="col-3" style={{ borderWidth: '1.5px', borderStyle: 'solid', borderColor: '#1aa179' }}>
            
            {profile?.profileDetails?.horoScope?.karkataR?.split(' ').map((item, index) => (
           <span style={{ color: item === 'ல' ? 'red' : 'black' }} key={index}>
           {item === ' ' ? '\u00A0' : item} {/* Handle spaces as well */}
           </span>
      ))}
            </div>
      </div>
      <div className="row">
          <div className="col-3" style={{ borderWidth: '1.5px', borderStyle: 'solid', borderColor: '#1aa179' }}>
            {profile?.profileDetails?.horoScope?.makaraR?.split(' ').map((item, index) => (
           <span style={{ color: item === 'ல' ? 'red' : 'black' }} key={index}>
           {item === ' ' ? '\u00A0' : item} {/* Handle spaces as well */}
           </span>
      ))}

          </div>
          <div className="col-6"></div>
          <div className="col-3" style={{ borderWidth: '1.5px', borderStyle: 'solid', borderColor: '#1aa179' }}>
            {profile?.profileDetails?.horoScope?.simhaR?.split(' ').map((item, index) => (
           <span style={{ color: item === 'ல' ? 'red' : 'black' }} key={index}>
           {item === ' ' ? '\u00A0' : item} {/* Handle spaces as well */}
           </span>
      ))}

          </div>
      </div>
      <div className="row">
          <div className="col-3" style={{ borderWidth: '1.5px', borderStyle: 'solid', borderColor: '#1aa179' }}>
            {profile?.profileDetails?.horoScope?.dhanuR?.split(' ').map((item, index) => (
          <span style={{ color: item === 'ல' ? 'red' : 'black' }} key={index}>
          {item === ' ' ? '\u00A0' : item} {/* Handle spaces as well */}
          </span>
      ))}

          </div>
          <div className="col-3" style={{ borderWidth: '1.5px', borderStyle: 'solid', borderColor: '#1aa179' }}>
            {profile?.profileDetails?.horoScope?.vrishikaR?.split(' ').map((item, index) => (
           <span style={{ color: item === 'ல' ? 'red' : 'black' }} key={index}>
           {item === ' ' ? '\u00A0' : item} {/* Handle spaces as well */}
           </span>
      ))}
          </div>
          <div className="col-3" style={{ borderWidth: '1.5px', borderStyle: 'solid', borderColor: '#1aa179' }}>
          {profile?.profileDetails?.horoScope?.tulaR?.split(' ').map((item, index) => (
         <span style={{ color: item === 'ல' ? 'red' : 'black' }} key={index}>
         {item === ' ' ? '\u00A0' : item} {/* Handle spaces as well */}
         </span>
          ))}

            </div>
          <div className="col-3" style={{ borderWidth: '1.5px', borderStyle: 'solid', borderColor: '#1aa179' }}>
          {profile?.profileDetails?.horoScope?.kanyaR?.split(' ').map((item, index) => (
         <span style={{ color: item === 'ல' ? 'red' : 'black' }} key={index}>
         {item === ' ' ? '\u00A0' : item} {/* Handle spaces as well */}
         </span>
          ))}
            </div>
      </div>
      </div>
      
      <br></br>

      {/* <div className="row">
      <div className="row">
          <div className="col-3 border border-success">
           <span style={{fontSize:"medium bold"}}>1</span> <span>குரு</span><span> பு</span>
            </div>
          <div className="col-3 border border-success"><span style={{color:"red"}} >//
             ல</span><span> சூ</span></div>
          <div className="col-3 border border-success">சந்</div>
          <div className="col-3 border border-success">சனி</div>
      </div>
      <div className="row">
          <div className="col-3 border border-success"> <p>செ</p><p></p></div>
          <div className="col-6 text-center">அம்சம்</div>
          
          <div className="col-3 border border-success">பு</div>
      </div>
      <div className="row">
          <div className="col-3 border border-primary">ரா</div>
          <div className="col-6"></div>
          <div className="col-3 border border-success">கே</div>
      </div>
      <div className="row">
          <div className="col-3 border border-success">சூ</div>
          <div className="col-3 border border-success">செ</div>
          <div className="col-3 border border-success">பு</div>
          <div className="col-3 border border-success">சந்</div>
      </div>
      </div> */}

<br/><br/>

<div className="row">
      <div className="row">
          <div className="col-3" style={{ borderWidth: '1.5px', borderStyle: 'solid', borderColor: '#1aa179' }}>
           {
           profile?.profileDetails?.horoScope?.meenaA?.split(' ').map((item, index) => (
            <span style={{ color: item === 'ல' ? 'red' : 'black' }} key={index}>
            {item === ' ' ? '\u00A0' : item} {/* Handle spaces as well */}
            </span>
      ))}
            </div>
          <div className="col-3" style={{ borderWidth: '1.5px', borderStyle: 'solid', borderColor: '#1aa179' }}>
{profile?.profileDetails?.horoScope?.meshaA?.split(' ').map((item, index) => (

          <span style={{ color: item === 'ல' ? 'red' : 'black' }} key={index}>
            {item === ' ' ? '\u00A0' : item} {/* Handle spaces as well */}
            </span>
      ))}
    

             </div>

          <div className="col-3" style={{ borderWidth: '1.5px', borderStyle: 'solid', borderColor: '#1aa179' }}>
          
          {profile?.profileDetails?.horoScope?.vrishbaA?.split(' ').map((item, index) => (
           <span style={{ color: item === 'ல' ? 'red' : 'black' }} key={index}>
           {item === ' ' ? '\u00A0' : item} {/* Handle spaces as well */}
           </span>
      ))}
    
            
            </div>
          <div className="col-3" style={{ borderWidth: '1.5px', borderStyle: 'solid', borderColor: '#1aa179' }}>
          {profile?.profileDetails?.horoScope?.mithunaA?.split(' ').map((item, index) => (
           <span style={{ color: item === 'ல' ? 'red' : 'black' }} key={index}>
           {item === ' ' ? '\u00A0' : item} {/* Handle spaces as well */}
           </span>
      ))}
            
            </div>
      </div>

      <div className="row">
          <div className="col-3" style={{ borderWidth: '1.5px', borderStyle: 'solid', borderColor: '#1aa179' }}>
            {profile?.profileDetails?.horoScope?.khumbhaA?.split(' ').map((item, index) => (
         <span style={{ color: item === 'ல' ? 'red' : 'black' }} key={index}>
         {item === ' ' ? '\u00A0' : item} {/* Handle spaces as well */}
         </span>
      ))}
          </div>
          <div className="col-6 text-center">அம்சம்</div>
          
          <div className="col-3" style={{ borderWidth: '1.5px', borderStyle: 'solid', borderColor: '#1aa179' }}>
            
            {profile?.profileDetails?.horoScope?.karkataA?.split(' ').map((item, index) => (
           <span style={{ color: item === 'ல' ? 'red' : 'black' }} key={index}>
           {item === ' ' ? '\u00A0' : item} {/* Handle spaces as well */}
           </span>
      ))}
            </div>
      </div>
      <div className="row">
          <div className="col-3" style={{ borderWidth: '1.5px', borderStyle: 'solid', borderColor: '#1aa179' }}>
            {profile?.profileDetails?.horoScope?.makaraA?.split(' ').map((item, index) => (
           <span style={{ color: item === 'ல' ? 'red' : 'black' }} key={index}>
           {item === ' ' ? '\u00A0' : item} {/* Handle spaces as well */}
           </span>
      ))}

          </div>
          <div className="col-6"></div>
          <div className="col-3" style={{ borderWidth: '1.5px', borderStyle: 'solid', borderColor: '#1aa179' }}>
            {profile?.profileDetails?.horoScope?.simhaA?.split(' ').map((item, index) => (
           <span style={{ color: item === 'ல' ? 'red' : 'black' }} key={index}>
           {item === ' ' ? '\u00A0' : item} {/* Handle spaces as well */}
           </span>
      ))}

          </div>
      </div>
      <div className="row">
          <div className="col-3" style={{ borderWidth: '1.5px', borderStyle: 'solid', borderColor: '#1aa179' }}>
            {profile?.profileDetails?.horoScope?.dhanuA?.split(' ').map((item, index) => (
          <span style={{ color: item === 'ல' ? 'red' : 'black' }} key={index}>
          {item === ' ' ? '\u00A0' : item} {/* Handle spaces as well */}
          </span>
      ))}

          </div>
          <div className="col-3" style={{ borderWidth: '1.5px', borderStyle: 'solid', borderColor: '#1aa179' }}>
            {profile?.profileDetails?.horoScope?.vrishikaA?.split(' ').map((item, index) => (
           <span style={{ color: item === 'ல' ? 'red' : 'black' }} key={index}>
           {item === ' ' ? '\u00A0' : item} {/* Handle spaces as well */}
           </span>
      ))}
          </div>
          <div className="col-3" style={{ borderWidth: '1.5px', borderStyle: 'solid', borderColor: '#1aa179' }}>
          {profile?.profileDetails?.horoScope?.tulaA?.split(' ').map((item, index) => (
         <span style={{ color: item === 'ல' ? 'red' : 'black' }} key={index}>
         {item === ' ' ? '\u00A0' : item} {/* Handle spaces as well */}
         </span>
          ))}

            </div>
          <div className="col-3" style={{ borderWidth: '1.5px', borderStyle: 'solid', borderColor: '#1aa179' }}>
          {profile?.profileDetails?.horoScope?.kanyaA?.split(' ').map((item, index) => (
         <span style={{ color: item === 'ல' ? 'red' : 'black' }} key={index}>
         {item === ' ' ? '\u00A0' : item} {/* Handle spaces as well */}
         </span>
          ))}
            </div>
      </div>
      </div>


    </div>

    </div>


    <br/><br/>
    <p className="h4">Contact Details</p>
    {profile && profile.brokerDetails ?(
    <div id="dvbrokerDetails" className="row">
     <div className="col-md-12">
    <div className="row">
    <label  style={{ fontWeight: 'bold' }} className="col-3 col-form-label">Broker Name</label>
    <div className="col-9">
      
        <label  className="form-control-plaintext">: {profile.brokerDetails.name}</label>
    </div>
    </div>
    <div className="row">
    <label  style={{ fontWeight: 'bold' }} className="col-3 col-form-label">Contact Number</label>
    <div className="col-9">
      
        <label  className="form-control-plaintext">: {profile.brokerDetails.phoneNumber}</label>
    </div>
    </div>

    <div className="row">
    <label  style={{ fontWeight: 'bold' }} className="col-3 col-form-label">Matrimony Name</label>
    <div className="col-9">
        <label  className="form-control-plaintext">: {profile.brokerDetails.description}</label>
    </div>
    </div>

    <div className="row">
    <label  style={{ fontWeight: 'bold' }} className="col-3 col-form-label">Address</label>
    <div className="col-9">
        <label  className="form-control-plaintext">: {profile.brokerDetails.address1} </label>
    </div>
    </div>


  </div>
  </div>):null
}




<br/><br/>
    <p className="h4">History Details</p>

    <div id="dvbrokerDetails" className="row">
     <div className="col-md-12">
    <div className="row">
    <label  style={{ fontWeight: 'bold' }} className="col-3 col-form-label">Created Date</label>
    <div className="col-9">
      
        <label  className="form-control-plaintext">: {profile.profileDetails.createdDate}</label>
    </div>
    </div>
    <div className="row">
    <label  style={{ fontWeight: 'bold' }} className="col-3 col-form-label">Created By</label>
    <div className="col-9">
      
        <label  className="form-control-plaintext">: {profile.profileDetails.createdBy}</label>
    </div>
    </div>

    <div className="row">
    <label  style={{ fontWeight: 'bold' }} className="col-3 col-form-label">Last Updated Date</label>
    <div className="col-9">
        <label  className="form-control-plaintext">: {profile.profileDetails.lastUpdatedDate}</label>
    </div>
    </div>

    <div className="row">
    <label  style={{ fontWeight: 'bold' }} className="col-3 col-form-label">Last Updated By</label>
    <div className="col-9">
        <label  className="form-control-plaintext">: {profile.profileDetails.lastUpdatedBy} </label>
    </div>
    </div>


  </div>
  </div>



<br/><br/>
<div className="row" >
       <div className="button-container">

              <button onClick={() => onPrintClick()} className="primarybutton">Print</button>
              <button className="secondarybutton" onClick={handleShow}>Delete</button>
              <button onClick={() => onCancelClick()} className="secondarybutton">Close</button>
       </div>

</div>
<br/>
<br/>


</div>

<Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Profile</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          {(hasError == true ? (<p className="errorText">Name should be profile name</p>) : null)}
<form action="">
          <div className="form-group">
            <input className="form-control" onKeyUpCapture={onDeleteNamechange} onChange={onchange} name="deleteName" id="deleteName" type="text" placeholder="Enter the name" />
          </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={onDeleteClick}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
