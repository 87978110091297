import { Carousal } from "../Common/Carousal"
import "../../scss/publicProfile.scss"
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from 'react-router-dom'
import { toast } from "react-toastify"
import { registerBroker, resetRegisterBroker } from "../../Features/Slices/brokSlice"
import{ValidateFields } from "../../Validation/Common/fieldValidation"
var   AddEditBrokerValidation = require('../../Validation/Config/AddEditBroker.json')

export function RegisterBroker() {

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch()
 // const brokerIdParam = searchParams.get('id')
  // dispatch(reset())
  const [formData, setFormData] = useState({
    name: '',
    phoneNumber: '',
    description: '',
    address1: '',
    address2: '',
    district: '',
    state: '',
    country: '',
    pincode: '',

  })

  const { name, phoneNumber, description, address1, address2, district, state, country, pincode } = formData

  const onchange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value
    }))
  }

  const { broker, isRegisterBrokerError, isRegisterBrokerSuccess,
    isRegisterBrokerLoading, RegisterBrokermessage } =
    useSelector(
      (state) => state.brok
    )

  useEffect(() => {
    if (isRegisterBrokerError) {
      toast.error(RegisterBrokermessage)
    }
    if (isRegisterBrokerSuccess) {
      dispatch(resetRegisterBroker())
      navigate('/AddBrokerImage?id='+ broker._id, { replace: true });
    }


  }, [broker, isRegisterBrokerError, isRegisterBrokerSuccess, isRegisterBrokerLoading, RegisterBrokermessage, navigate], dispatch)

  

  const onSubmit = (e) => {
    e.preventDefault();

    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.name
    }))

    const brokerData = {
      name, phoneNumber, description, address1, address2, district, state,
      country, pincode
    }

    let message = "";
    let hasRequiredfieldValidation = false
    
    const brokerReqFields = {
      name, phoneNumber, description, address1, district, state,
      country, pincode
    }

    for (const [key, value] of Object.entries(brokerReqFields)) {

        if(value == ""){
          hasRequiredfieldValidation = true;
         return toast.error('Please fill (*) required field')
        }
    }

if(hasRequiredfieldValidation == false)
{
    for (const [key, value] of Object.entries(brokerData)) {
      let arrValidation = AddEditBrokerValidation.filter(validatebroker => validatebroker.fieldName === key)
      for (const currentObject of arrValidation) {
          let message = ValidateFields(currentObject, value);
          if (message != '') {
            toast.error(message)
          }
      }
    }
  }

    if(hasRequiredfieldValidation == false && message == '')
    {
      dispatch(registerBroker(brokerData))

      if (isRegisterBrokerSuccess == true) {
        toast.success(RegisterBrokermessage)
      }
      else if (isRegisterBrokerError == true) {
        toast.success(RegisterBrokermessage)
      }
    }
  }

    const onCancelClick = () => {
      navigate('/BrokerList')
    }

    const onResetClick = (e) => {
      
      //dispatch(resetRegisterBroker())
    }
 

    return (
      <>
        <div className="content">
          <p className="h4">Register Broker</p>
          <form action="" onSubmit={onSubmit}>
            <div id="dvRegisterbroker" className="row">
              <div className="col-12">

                <div className="row">

                  <div className="form-group col-4">
                    <label htmlFor="name">Name<span className="labelerror"><b>*</b></span></label>
                    <input type="text" className={ name ==''? "form-control bordererror": "form-control"} name="name" id="name" onChange={onchange}></input>
                  </div>  
                  <div className="form-group col-4">
                    <label htmlFor="phoneNumber">Phone number<span className="labelerror"><b>*</b></span></label>
                    <input type="text" className={ phoneNumber =='' ? "form-control bordererror": "form-control"} name="phoneNumber" id="phoneNumber" onChange={onchange}></input>
                  </div>
                  <div className="form-group col-4">
                    <label htmlFor="description">Description<span className="labelerror"><b>*</b></span></label>
                    <input type="text" className={ description =='' ? "form-control bordererror": "form-control"} name="description" id="description" onChange={onchange} ></input>
                  </div>

                </div>
                <div className="row">
                  <div className="form-group col-4">
                    <label htmlFor="address1">Address1<span className="labelerror"><b>*</b></span></label>
                    <input type="text" className={ address1 =='' ? "form-control bordererror": "form-control"} name="address1" id="address1" onChange={onchange}></input>
                  </div>
                  <div className="form-group col-4">
                    <label htmlFor="address2">Address2</label>
                    <input type="text" className="form-control" name="address2" id="address2" onChange={onchange} ></input>
                  </div>
                  <div className="form-group col-4">
                    <label htmlFor="district">District <span className="labelerror"><b>*</b></span></label>
                    <select  className={ district =='' ? "form-select form-select-sm bordererror": "form-select form-select-sm"} name="district" id="district" onChange={onchange} aria-label=".form-select-sm example">
                      <option value="">Select</option>
                      <option value={"Kanyakumari"}>Kanyakumari</option>
                      <option value={"Thirunelveli"}>Thirunelveli</option>
                      <option value={"Thoothukudi"}>Thoothukudi</option>
                      <option value={"Madurai"}>Madurai</option>
                    </select>
                  </div>
                </div>

                <div className="row">
                  <div className="form-group col-4">
                    <label htmlFor="state">State <span className="labelerror"><b>*</b></span></label>
                    <select  className={ state =='' ? "form-select form-select-sm bordererror": "form-select form-select-sm"} name="state" id="state" onChange={onchange} aria-label=".form-select-sm example">
                      <option value="">Select</option>
                      <option value={"Tamilnadu"}>Tamilnadu</option>
                    </select>
                  </div>
                  <div className="form-group col-4">
                    {/* <label htmlFor="country">Country</label>
                <input type="text"  className="form-control" name="country"  id="country" onChange={onchange} ></input> */}
                    <label htmlFor="country">Country <span className="labelerror"><b>*</b></span></label>
                    <select  className={ country =='' ? "form-select form-select-sm bordererror": "form-select form-select-sm"} name="country" id="country" onChange={onchange} aria-label=".form-select-sm example">
                      <option value="">Select</option>
                      <option value={"India"}>India</option>
                    </select>
                  </div>
                  <div className="form-group col-4">
                    <label htmlFor="pincode">Pincode <span className="labelerror"><b>*</b></span></label>
                    <input type="text" className={ pincode =='' ? "form-control bordererror": "form-control"} name="pincode" id="pincode" onChange={onchange}></input>
                  </div>
                </div>
                
              </div>

            </div>
            <div className="row"></div>
            <div>

              <button className="btn btn-success me-md-2" type="submit">Register</button>
              <button onClick={() => onResetClick()} className="btn btn-danger me-md-2">Reset</button>
              <button onClick={() => onCancelClick()} className="btn btn-danger" type="submit">Cancel</button>


            </div>
          </form>
        </div>
      </>
    )
  }