import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom'
import {useDispatch, useSelector} from  'react-redux'
import {registeruser,getRegisterUserOTP, reset} from "../../Features/Slices/authSlice"
import {toast} from "react-toastify"
import "../../scss/register.css"
export function Register() {

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [formData,setFormData] = useState({
    name:'',
    email:'',
    phoneNumber:'',
    password:'',
    confirmPassword:'',
    otp:''
})

const {name,email,phoneNumber,password,confirmPassword,otp} = formData

    const onchange = (e) => {
        setFormData((prevState)=>({
            ...prevState,
            [e.target.name]:e.target.value
        }))
    }

const {user,isError,isSuccess,isLoading,message,isSuccessMessage,isOTPSuccess,messageOTP} =
    useSelector(
      (state) => state.auth
    )

    useEffect(()=>{

      dispatch(reset())
      
      if(!isSuccessMessage && message !=''){
          toast.error(message)
      }
      if(isSuccessMessage && message !='')
      {
          toast.success(message)
          navigate('/', { replace: true });
      }
      if(isOTPSuccess && messageOTP !='')
        {
          toast.success(messageOTP)
        }

      dispatch(reset())
  },[user,isError,isSuccess,isLoading,message,isSuccessMessage,isOTPSuccess,messageOTP,navigate],dispatch)

  const onSubmit = (e) =>{
    e.preventDefault();

    setFormData((prevState)=>({
        ...prevState,
        [e.target.name]:e.target.name
    }))

    const userData ={
      name,email,phoneNumber,password,otp,confirmPassword
    }
    var data = dispatch(registeruser(userData))

    // if(isSuccessMessage)
    //   {
    //     toast.success(message)
    //   }
    //   else{
    //     toast.error(message)
    //   }
}

  const onClose = (e) =>{
    navigate('/', { replace: true });
  }
  const [isgetOTP, setIsgetOTP] = useState(false);
  const getOTP = (e) =>{
    const userData ={
      name,email,phoneNumber
    }
    dispatch(getRegisterUserOTP(userData))
    setIsgetOTP(true)
  }


  return (<>
    <div className="page-content page-container" id="page-content">
    
      <div className="padding">
        <div className="row container d-flex justify-content-center">
          <div id="dvuserregister" className="col-md-6 col-lg-4">
            <form className="card">
              <h5 className="card-title fw-400">Sign Up</h5>

              <div className="card-body">

                <div className="form-group">
                  <input className="form-control" onChange={onchange} name="name" id="name" type="text" placeholder="User name" />
                </div>
                <div className="form-group">
                  <input className="form-control" onChange={onchange} name="email" id="email" type="text" placeholder="Email" />
                </div>

                <div className="form-group">
                  <input className="form-control" onChange={onchange} name="phoneNumber" id="phoneNumber" type="text" placeholder="Phone number" />
                </div>

                <div className="form-group">
                  <input className="form-control" onChange={onchange} name="password" id="password" type="password" placeholder="Password" />
                </div>

                <div className="form-group">
                  <input className="form-control" type="password" onChange={onchange} name="confirmPassword" id="confirmPassword" placeholder="Confirm Password" />
                </div>

                

                <div className="form-group">
                  <div className="row">
                    <div className="col-md-4">
                   <button type="button" onClick={getOTP} className="btn-sm btn-success">Get OTP</button>
                   </div>
                   <div className="col-md-8">
                  <input className="form-control" name="otp" id="otp" onChange={onchange} type="text" placeholder="OTP" />
                  </div>
                </div>
                </div>


                {/* <button className="btn btn-block btn-bold btn-primary">Sign Up</button>
                <button onClick={onClose} className="btn btn-block btn-bold btn-primary">Close</button> */}

                <button type="button" onClick={onSubmit} className="btn btn-success">Sign Up</button>
                <button  onClick={onClose} type="button" className="btn btn-danger m-l-5">Close</button>
              </div>
            </form>
          </div>
        </div>
      </div>
      
    </div>
  </>)
}