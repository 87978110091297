export function ProfileHoroscope() {

    return (
        <>
        <div className="content">

        <div className="col-md-8">
      <div className="row">
      <div className="row">
          <div className="col-3 border border-primary">
           {/* <span style={{fontSize:"medium bold"}}>1</span> <span>குரு</span><span> பு</span> */}
           <div className="container mt-5">
  <select className="selectpicker" multiple aria-label="Default select example" data-live-search="true">
    <option value="1">One</option>
    <option value="2">Two</option>
    <option value="3">Three</option>
    <option value="4">Four</option>
  </select>
</div>
            </div>
          <div className="col-3 border border-primary"><span style={{color:"red"}} >//
             ல</span><span> சூ</span></div>
          <div className="col-3 border border-primary">சந்</div>
          <div className="col-3 border border-primary">சனி</div>
      </div>
      <div className="row">
          <div className="col-3 border border-primary"> <p>செ</p><p></p></div>
          <div className="col-6 text-center">ராசி</div>
          
          <div className="col-3 border border-primary">பு</div>
      </div>
      <div className="row">
          <div className="col-3 border border-primary">ரா</div>
          <div className="col-6"></div>
          <div className="col-3 border border-primary">கே</div>
      </div>
      <div className="row">
          <div className="col-3 border border-primary">சூ</div>
          <div className="col-3 border border-primary">செ</div>
          <div className="col-3 border border-primary">பு</div>
          <div className="col-3 border border-primary">சந்</div>
      </div>
      </div>
      
      <br></br>

      <div className="row">
      <div className="row">
          <div className="col-3 border border-success">
           <span style={{fontSize:"medium bold"}}>1</span> <span>குரு</span><span> பு</span>
            </div>
          <div className="col-3 border border-success"><span style={{color:"red"}} >//
             ல</span><span> சூ</span></div>
          <div className="col-3 border border-success">சந்</div>
          <div className="col-3 border border-success">சனி</div>
      </div>
      <div className="row">
          <div className="col-3 border border-success"> <p>செ</p><p></p></div>
          <div className="col-6 text-center">அம்சம்</div>
          
          <div className="col-3 border border-success">பு</div>
      </div>
      <div className="row">
          <div className="col-3 border border-primary">ரா</div>
          <div className="col-6"></div>
          <div className="col-3 border border-success">கே</div>
      </div>
      <div className="row">
          <div className="col-3 border border-success">சூ</div>
          <div className="col-3 border border-success">செ</div>
          <div className="col-3 border border-success">பு</div>
          <div className="col-3 border border-success">சந்</div>
      </div>
      </div>

    </div>

        </div>
        </>
        )
}