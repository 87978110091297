import { json } from "react-router-dom";

const getUserData = () => {
   
    return JSON.parse(sessionStorage.getItem("user"))
  };

  const sessionData ={
    getUserData,
}

export default sessionData 