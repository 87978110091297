import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { registeruser, getResetPasswordOTP, resetUserPassword, reset } from "../../Features/Slices/authSlice"
import { toast } from "react-toastify"
import "../../scss/register.css"
export function ResetUser() {

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phoneNumber: '',
    password: '',
    confirmPassword: '',
    otp: ''
  })

  const { name, email, phoneNumber, password, confirmPassword, otp } = formData

  const onchange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value
    }))
  }

  const { user, isError, isSuccess, isLoading,
    message, isOTPResetSuccess, messageOTPReset,isOTPResetError,
     isSubmitResetError, isSubmitResetSuccess, messageResetSubmit
  } =
    useSelector(
      (state) => state.auth
    )

  useEffect(() => {

    dispatch(reset())

    if (isError) {
      toast.error(message)
    }
    if (isSuccess) {
      //toast.success('User registred successfully.')
      navigate('/', { replace: true });
    }
    debugger
    if (isOTPResetSuccess) {
      toast.success(messageOTPReset)
    }

    if (isOTPResetError) {
      toast.error(messageOTPReset)
    }
    if (isSubmitResetSuccess) {
      toast.success(messageResetSubmit)
    }

    if (isSubmitResetError) {
      toast.error(messageResetSubmit)
    }


    dispatch(reset())
  }, [user, isError, isSuccess, isLoading, message, 
    isOTPResetSuccess, messageOTPReset, isOTPResetError, 
    isSubmitResetError, isSubmitResetSuccess, messageResetSubmit,
    navigate], dispatch)

  const onSubmit = (e) => {
    e.preventDefault();

    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.name
    }))

    const userData = {
      name, email, phoneNumber, password, confirmPassword, otp
    }
    debugger
    var data = dispatch(resetUserPassword(userData))
  }

  const onSendOTPClick = (e) => {

    const userData = {
      name, email, phoneNumber
    }
    debugger
    dispatch(getResetPasswordOTP(userData))
  }

  const onClose = (e) => {
    navigate('/', { replace: true });
  }


  return (<>
    <div className="page-content page-container" id="page-content">

      <div className="padding">
        <div className="row container d-flex justify-content-center">
          <div id="dvuserreset" className="col-md-6 col-lg-4">
            <form className="card">
              <h5 className="card-title fw-400">Reset Password</h5>

              <div className="card-body">

                <div className="form-group">
                  <input className="form-control" onChange={onchange} name="email" id="email" type="text" placeholder="User name / Email" />
                </div>

                <div className="form-group">
                  (OR)
                </div>

                <div className="form-group">
                  <input className="form-control" onChange={onchange} name="phoneNumber" id="phoneNumber" type="text" placeholder="Phone number" />
                </div>

                <div className="row">
                  <button type="button" onClick={onSendOTPClick} className="btn btn-secondary">Get OTP</button>
                </div>
                <div className="p-2"></div>

                <div className="form-group">
                  <input className="form-control" onChange={onchange} name="otp" id="otp" type="text" placeholder="Enter OTP" />
                </div>

                <div className="form-group">
                  <input className="form-control" onChange={onchange} name="password" id="password" type="password" placeholder="Password" />
                </div>

                <div className="form-group">
                  <input className="form-control" type="password" onChange={onchange} name="confirmPassword" id="confirmPassword" placeholder="Confirm Password" />
                </div>


                {/* <button className="btn btn-block btn-bold btn-primary">Sign Up</button>
                <button onClick={onClose} className="btn btn-block btn-bold btn-primary">Close</button> */}

                <button type="button" onClick={onSubmit} className="btn btn-success">Submit</button>
                <button onClick={onClose} type="button" className="btn btn-danger m-l-5">Close</button>
              </div>
            </form>
          </div>
        </div>
      </div>

    </div>
  </>)
}