import{createSlice,createAsyncThunk} from '@reduxjs/toolkit'
import profileService  from '../Services/profService'
import masterService  from '../Services/masterService'
import azureService  from '../Services/azureService'

const initialState ={
    profiles:[],
    profileTotal:0,
    profileImageList:[],
    
    profileDetails:{},
    isError:false,
    isSuccess:false,
    isLoading:false,
    message:'',

    isRegisterProfileError:false,
    isRegisterProfileSuccess:false,
    isRegisterProfileLoading:false,
    registerProfilemessage:'',
    registerProfileId:'',

    isUpdatedProfileLoading:false,
    isUpdatedProfileSuccess:false,
    updatedProfilemessage:'',
    isUpdatedProfileError:false,

    isBrokerDetailError:false,
    isBrokerDetailSuccess:false,
    isBrokerDetailLoading:false,
    prokerDetails:{},

    isStarListError:false,
    isStarListSuccess:false,
    isStarListLoading:false,
    stars:[],

    isDistrictListError:false,
    isDistrictListSuccess:false,
    isDistrictListLoading:false,
    districts:[],
    

    isRasiListError:false,
    isRasiListSuccess:false,
    isRasiListLoading:false,
    rasis:[],

    isJobListError:false,
    isJobListSuccess:false,
    isJobListLoading:false,
    jobs:[],

    isQualificationListError:false,
    isQualificationSuccess:false,
    isQualificationLoading:false,
    qualifications:[],

    religions:[],
    isReligionError:false,
    isReligionSuccess:false,
    isReligionLoading:false,

    castes:[],
    iscasteError:false,
    iscasteSuccess:false,
    iscasteLoading:false,

    isImageListError:false,
    isImageSuccess:false,
    isImageLoading:false,
    Images:[],

    isUploadProfileError:false,
    isUploadProfileSuccess:false,
    isUploadProfileLoading:false,
    uploadProfilemessage:'',

    isRemoveProfileImageError:false,
    isRemoveProfileImageSuccess:false,
    isRemoveProfileImageLoading:false,
    removeProfileImagemessage:null,

    
    isdeleteProfileError:false,
    isdeleteProfileSuccess:false,
    isdeleteProfileLoading:false,
    messageResetdeleteProfile:'',

    isUpdateHoroscopeProfileError:false,
    isUpdateHoroscopeSuccess:false,
    isUpdateHoroscopeLoading:false,
    messageUpdateHoroscope:'',

    issetProfilePictureError : false,
    issetProfilePictureSuccess:false,
    issetProfilePictureLoading:false,
    messagesetProfile:''
}

export const registerProfile = createAsyncThunk(
    'prof/registerProfile',
    async(data,thunkAPI)=>{
        try{
             return await profileService.registerProfile(data)
        }
        catch(error){
            const message = (error.response && error.response.data &&
                error.response.data.message) || error.message || error.tostring()

                return thunkAPI.rejectWithValue(message)
        }
    }
)

export const updateProfile = createAsyncThunk(
    'prof/updateProfile',
    async(data,thunkAPI)=>{
        try{
             return await profileService.updateProfile(data)
        }
        catch(error){
            const message = (error.response && error.response.data &&
                error.response.data.message) || error.message || error.tostring()

                return thunkAPI.rejectWithValue(message)
        }
    }
)




export const getAllProfilesByBrokerId = createAsyncThunk(
    'brok/getAllProfilesByBrokerId',
    async(data,thunkAPI)=>{
        try{
           
             return await profileService.fetchprofileByBroker(data)
        }
        catch(error){
            const message = (error.response && error.response.data &&
                error.response.data.message) || error.message || error.tostring()

                return thunkAPI.rejectWithValue(message)
        }
    }
)

export const searchProfile = createAsyncThunk(
    'brok/searchProfile',
    async(data,thunkAPI)=>{
        try{
           
             return await profileService.serchProfiles(data)
        }
        catch(error){
            const message = (error.response && error.response.data &&
                error.response.data.message) || error.message || error.tostring()

                return thunkAPI.rejectWithValue(message)
        }
    }
)

export const getProfileDetailsById = createAsyncThunk(
    'brok/getProfileDetailsById',
    async(data,thunkAPI)=>{
        try{
           
             return await profileService.fetchprofileDetailsById(data)
        }
        catch(error){
            const message = (error.response && error.response.data &&
                error.response.data.message) || error.message || error.tostring()

                return thunkAPI.rejectWithValue(message)
        }
    }
)

export const getbrokerDetailsById = createAsyncThunk(
    'brok/getbrokerDetailsById',
    async(data,thunkAPI)=>{
        try{
           
             return await profileService.getbrokerDetailsById(data)
        }
        catch(error){
            const message = (error.response && error.response.data &&
                error.response.data.message) || error.message || error.tostring()

                return thunkAPI.rejectWithValue(message)
        }
    }
)

export const getAllStars = createAsyncThunk(
    'brok/getAllStars',
    async(user,thunkAPI)=>{
        try{
             return await masterService.getAllStars()
        }
        catch(error){
            const message = (error.response && error.response.data &&
                error.response.data.message) || error.message || error.tostring()

                return thunkAPI.rejectWithValue(message)
        }
    }
)


export const uploadfile = createAsyncThunk(
    'brok/uploadfile',
    async(fileData,thunkAPI)=>{
        try{
             return await azureService.uploadfile(fileData);
        }
        catch(error){
            const message = (error.response && error.response.data &&
                error.response.data.message) || error.message || error.tostring()

                return thunkAPI.rejectWithValue(message)
        }
    }
)

export const updateHoroscope = createAsyncThunk(
    'brok/updateHoroscope',
    async(data,thunkAPI)=>{
        try{
             return await profileService.updateHoroscope(data);
        }
        catch(error){
            const message = (error.response && error.response.data &&
                error.response.data.message) || error.message || error.tostring()

                return thunkAPI.rejectWithValue(message)
        }
    }
)

export const getAllRasis = createAsyncThunk(
    'brok/getAllRasis',
    async(user,thunkAPI)=>{
        try{
             return await masterService.getAllRasis()
        }
        catch(error){
            const message = (error.response && error.response.data &&
                error.response.data.message) || error.message || error.tostring()

                return thunkAPI.rejectWithValue(message)
        }
    }
)

export const getAllDistricts = createAsyncThunk(
    'brok/getAllDistricts',
    async(user,thunkAPI)=>{
        try{
             return await masterService.getAllDistricts()
        }
        catch(error){
            const message = (error.response && error.response.data &&
                error.response.data.message) || error.message || error.tostring()

                return thunkAPI.rejectWithValue(message)
        }
    }
)

export const getAllJobs= createAsyncThunk(
    'brok/getAllJobs',
    async(user,thunkAPI)=>{
        try{
             return await masterService.getAllJobs()
        }
        catch(error){
            const message = (error.response && error.response.data &&
                error.response.data.message) || error.message || error.tostring()

                return thunkAPI.rejectWithValue(message)
        }
    }
)

export const getAllQualifications= createAsyncThunk(
    'brok/getAllQualifications',
    async(user,thunkAPI)=>{
        try{
             return await masterService.getAllQualifications()
        }
        catch(error){
            const message = (error.response && error.response.data &&
                error.response.data.message) || error.message || error.tostring()

                return thunkAPI.rejectWithValue(message)
        }
    }
)

export const getAllReligions = createAsyncThunk(
    'brok/getAllReligions',
    async(user,thunkAPI)=>{
        try{
             return await masterService.getAllReligions();
        }
        catch(error){
            const message = (error.response && error.response.data &&
                error.response.data.message) || error.message || error.tostring()

                return thunkAPI.rejectWithValue(message)
        }
    }
)

export const getAllCastes = createAsyncThunk(
    'brok/getAllCastes',
    async(user,thunkAPI)=>{
        try{
             return await masterService.getAllCastes();
        }
        catch(error){
            const message = (error.response && error.response.data &&
                error.response.data.message) || error.message || error.tostring()

                return thunkAPI.rejectWithValue(message)
        }
    }
)


export const getProfileImageUrl = createAsyncThunk(
    'brok/getProfileImageUrl',
    async(data,thunkAPI)=>{
        try{
           
             return await profileService.getProfileImageUrl(data)
        }
        catch(error){
            const message = (error.response && error.response.data &&
                error.response.data.message) || error.message || error.tostring()

                return thunkAPI.rejectWithValue(message)
        }
    }
)

export const removeProfileImage = createAsyncThunk(
    'brok/removeProfileImage',
    async(data,thunkAPI)=>{
        try{
           
             return await profileService.removeProfileImage(data)
        }
        catch(error){
            const message = (error.response && error.response.data &&
                error.response.data.message) || error.message || error.tostring()

                return thunkAPI.rejectWithValue(message)
        }
    }
)

export const setProfilePicture = createAsyncThunk(
    'brok/setProfilePicture',
    async(data,thunkAPI)=>{
        try{
           
             return await profileService.setProfilePicture(data)
        }
        catch(error){
            const message = (error.response && error.response.data &&
                error.response.data.message) || error.message || error.tostring()

                return thunkAPI.rejectWithValue(message)
        }
    }
)


export const deleteProfile = createAsyncThunk(
    'brok/deleteProfile',
    async(data,thunkAPI)=>{
        try{
           
             return await profileService.deleteProfile(data)
        }
        catch(error){
            const message = (error.response && error.response.data &&
                error.response.data.message) || error.message || error.tostring()

                return thunkAPI.rejectWithValue(message)
        }
    }
)




export const profSlice = createSlice({
    name:'prof',
    initialState,
    reducers:{
        resetRegisterProfile:(state)=>{
            state.isRegisterProfileLoading = false
            state.isRegisterProfileSuccess = false
            state.isRegisterProfileError = false
            state.registerProfilemessage =''
            state.registerProfileId =''
        },
        resetRegisterMessages:(state)=>{
            state.isRegisterProfileSuccess = false
            state.isRegisterProfileError = false
            state.registerProfilemessage =''
        },
        resetUpdatedProfile:(state)=>{
            state.isUpdatedProfileLoading = false
            state.isUpdatedProfileSuccess = false
            state.isUpdatedProfileError = false
            state.updatedProfilemessage =''
        },
        resetUpdatedProfileMessage:(state)=>{
            state.isUpdatedProfileSuccess = false
            state.isUpdatedProfileError = false
        },
        resetDeleteProfileMessage:(state)=>{
            state.isdeleteProfileError = false
        },
        resetAllProfilesByBrokerId:(state)=>{
            state.isAllProfilesByBrokerIdLoading = false
            state.isAllProfilesByBrokerIdSuccess = false
            state.isAllProfilesByBrokerIdError   = false
            state.allProfilesByBrokerIdmessage   = ''
        },
        resetProfileDetailsById:(state)=>{
            state.isProfileDetailsByIdLoading = false
            state.isProfileDetailsByIdError = false
            state.isProfileDetailsByIdSuccess = false
            state.profileDetailsByIdmessage = ''
        },
        resetDistrictsByState:(state)=>{
            state.isDistrictListLoading = false
            state.isDistrictListSuccess = false
            state.isDistrictListError = false
        },
        resetImageUploadByState:(state)=>{
            state.uploadProfilemessage = ''
            state.isUploadProfileSuccess = false
            state.isUploadProfileLoading = false
            state.isUploadProfileError = false
        },
        resetProfileList:(state)=>{
            state.profiles = []
            state.profileTotal=0
            state.profileImageList =[]
            state.isAllProfilesByBrokerIdLoading = false
            state.isAllProfilesByBrokerIdSuccess = false
            state.isAllProfilesByBrokerIdError = false
        },
        resetdeleteProfile:(state)=>{
            state.isdeleteProfileError = false
            state.isdeleteProfileSuccess = false
            state.isdeleteProfileLoading = false
            state.messagedeleteProfile =''
        },
        resetUpdateHoroscope:(state)=>{
            state.isUpdateHoroscopeProfileError = false
            state.isUpdateHoroscopeSuccess = false
            state.isUpdateHoroscopeLoading = false
            state.messageUpdateHoroscope =''
        },
        resetProfilePicture:(state)=>{
            state.issetProfilePictureError = false
            state.issetProfilePictureSuccess = false
            state.issetProfilePictureLoading = false
            state.messagesetProfile =''
        }
    },
    extraReducers:(builder)=>{
        builder
        .addCase(registerProfile.pending,(state)=>{
            state.isRegisterProfileLoading = true;
        })
        .addCase(registerProfile.fulfilled,(state,action)=>{
            state.isRegisterProfileLoading = false
            state.isRegisterProfileSuccess = true
            state.registerProfileId = action.payload.Id
        })
        .addCase(registerProfile.rejected,(state,action)=>{
            state.isRegisterProfileLoading = false
            state.isRegisterProfileError = true
            state.registerProfilemessage = action.payload
            state.profiles = []
        })
        .addCase(updateProfile.pending,(state)=>{
            state.isUpdatedProfileLoading = true;
        })
        .addCase(updateProfile.fulfilled,(state,action)=>{
            state.isUpdatedProfileLoading = false
            state.isUpdatedProfileSuccess = true
            state.updatedProfilemessage = action.payload.message
        })
        .addCase(updateProfile.rejected,(state,action)=>{
            state.isUpdatedProfileLoading = false
            state.isUpdatedProfileError = true
            state.updatedProfilemessage = action.payload.message
        })
        .addCase(getAllProfilesByBrokerId.pending,(state)=>{
            state.isAllProfilesByBrokerIdLoading = true;
        })
        .addCase(getAllProfilesByBrokerId.fulfilled,(state,action)=>{
            state.isAllProfilesByBrokerIdLoading = false
            state.isAllProfilesByBrokerIdSuccess = true
            state.profiles = action.payload.profiles
            state.profileTotal =  action.payload.totalRecourd
            state.profileImageList =action.payload.imageUrls
        })
        .addCase(getAllProfilesByBrokerId.rejected,(state,action)=>{
            state.isAllProfilesByBrokerIdLoading = false
            state.isAllProfilesByBrokerIdError = true
            state.allProfilesByBrokerIdmessage = action.payload
            state.profiles = []
            state.profileTotal = 0
            state.profileImageList =[]
        })
        .addCase(searchProfile.pending,(state)=>{
            state.isAllProfilesByBrokerIdLoading = true;
        })
        .addCase(searchProfile.fulfilled,(state,action)=>{
            state.isAllProfilesByBrokerIdLoading = false
            state.isAllProfilesByBrokerIdSuccess = true
            state.profiles = action.payload.profiles
            state.profileTotal =  action.payload.totalRecourd
            state.profileImageList =action.payload.imageUrls
        })
        .addCase(searchProfile.rejected,(state,action)=>{
            state.isAllProfilesByBrokerIdLoading = false
            state.isAllProfilesByBrokerIdError = true
            state.allProfilesByBrokerIdmessage = action.payload
            state.profiles = []
            state.profileTotal = 0
            state.profileImageList =[]
        })
        .addCase(getProfileDetailsById.pending,(state)=>{
            state.isProfileDetailsByIdLoading = true;
        })
        .addCase(getProfileDetailsById.fulfilled,(state,action)=>{
            state.isProfileDetailsByIdLoading = false
            state.isProfileDetailsByIdSuccess = true
            state.profileDetails = action.payload
        })
        .addCase(getProfileDetailsById.rejected,(state,action)=>{
            state.isProfileDetailsByIdLoading = false
            state.isProfileDetailsByIdError = true
            state.profileDetailsByIdmessage = action.payload
            state.profileDetails ={}
        })
        .addCase(getbrokerDetailsById.pending,(state)=>{
            state.isBrokerDetailLoading = true;
        })
        .addCase(getbrokerDetailsById.fulfilled,(state,action)=>{
            state.isBrokerDetailLoading = false
            state.isBrokerDetailSuccess = true
            state.brokerDetails = action.payload
        })
        .addCase(getbrokerDetailsById.rejected,(state,action)=>{
            state.isBrokerDetailLoading = false
            state.isBrokerDetailError = true
            state.brokerDetails = {}
        })
        .addCase(getAllStars.pending,(state)=>{
            state.isStarListLoading = true;
        })
        .addCase(getAllStars.fulfilled,(state,action)=>{
            state.isStarListLoading = false
            state.isStarListSuccess = true
            state.stars = action.payload
        })
        .addCase(getAllStars.rejected,(state,action)=>{
            state.isStarListLoading = false
            state.isStarListError = true
            state.stars = []
            
        })
        .addCase(getAllRasis.pending,(state)=>{
            state.isRasiListLoading = true;
        })
        .addCase(getAllRasis.fulfilled,(state,action)=>{
            state.isRasiListLoading = false
            state.isRasiListSuccess = true
            state.rasis = action.payload
        })
        .addCase(getAllRasis.rejected,(state,action)=>{
            state.isRasiListLoading = false
            state.isRasiListError = true
            state.rasis = []
            
        })
        .addCase(getAllDistricts.pending,(state)=>{
            state.isDistrictListLoading = true;
        })
        .addCase(getAllDistricts.fulfilled,(state,action)=>{
            state.isDistrictListLoading = false
            state.isDistrictListSuccess = true
            state.districts = action.payload
        })
        .addCase(getAllDistricts.rejected,(state,action)=>{
            state.isDistrictListLoading = false
            state.isDistrictListError = true
            state.districts = []
            
        })
        .addCase(getAllJobs.pending,(state)=>{
            state.isJobListLoading = true;
        })
        .addCase(getAllJobs.fulfilled,(state,action)=>{
            state.isJobListLoading = false
            state.isJobListSuccess = true
            state.jobs = action.payload
        })
        .addCase(getAllJobs.rejected,(state,action)=>{
            state.isJobListLoading = false
            state.isJobListError = true
            state.jobs = []
        })
        .addCase(getAllQualifications.pending,(state)=>{
            state.isQualificationLoading = true;
        })
        .addCase(getAllQualifications.fulfilled,(state,action)=>{
            state.isQualificationLoading = false
            state.isQualificationSuccess = true
            state.qualifications = action.payload
        })
        .addCase(getAllQualifications.rejected,(state,action)=>{
            state.isQualificationLoading = false
            state.isQualificationListError = true
            state.qualifications = []
        })
        .addCase(getAllReligions.pending,(state)=>{
            state.isReligionLoading = true;
        })
        .addCase(getAllReligions.fulfilled,(state,action)=>{
            state.isReligionLoading = false
            state.isReligionSuccess = true
            state.religions = action.payload
        })
        .addCase(getAllReligions.rejected,(state,action)=>{
            state.isReligionLoading = false
            state.isReligionError = true
            state.religions = []
            
        })
        .addCase(getAllCastes.pending,(state)=>{
            state.iscasteLoading = true;
        })
        .addCase(getAllCastes.fulfilled,(state,action)=>{
            state.iscasteLoading = false
            state.iscasteSuccess = true
            state.castes = action.payload
        })
        .addCase(getAllCastes.rejected,(state,action)=>{
            state.iscasteLoading = false
            state.iscasteError = true
            state.castes = []
            
        })

        .addCase(uploadfile.pending,(state)=>{
            state.isUploadProfileLoading = true;
        })
        .addCase(uploadfile.fulfilled,(state,action)=>{
            state.isUploadProfileLoading = false
            state.isUploadProfileSuccess = true
            state.UploadProfilemessage = action.payload.data
        })
        .addCase(uploadfile.rejected,(state,action)=>{
            state.isUploadProfileLoading = false
            state.isUploadProfileError = true
            state.isUploadProfileSuccess = false
            state.UploadProfilemessage = ''
        })

        .addCase(getProfileImageUrl.pending,(state)=>{
            state.isImageLoading = true;
        })
        .addCase(getProfileImageUrl.fulfilled,(state,action)=>{
            state.isImageLoading = false
            state.isImageSuccess = true
            state.Images = action.payload
        })
        .addCase(getProfileImageUrl.rejected,(state,action)=>{
            state.isImageLoading = false
            state.isImageListError = true
            state.Images = []
        })
        //removeProfileImage

        .addCase(removeProfileImage.pending,(state)=>{
            state.isRemoveProfileImageLoading = true;
        })
        .addCase(removeProfileImage.fulfilled,(state,action)=>{
            state.isRemoveProfileImageLoading = false
            state.isRemoveProfileImageSuccess = true
            state.removeProfileImagemessage = action.payload
        })
        .addCase(removeProfileImage.rejected,(state,action)=>{
            state.isRemoveProfileImageLoading = false
            state.isRemoveProfileImageError = true
            state.removeProfileImagemessage = null
        }).
        addCase(deleteProfile.pending,(state)=>{
            state.isdeleteProfileLoading = true;
        })
        .addCase(deleteProfile.fulfilled,(state,action)=>{
            state.isdeleteProfileLoading = false
            state.isdeleteProfileSuccess = true
            state.messagedeleteProfile = action.payload.message
        }) 
        .addCase(deleteProfile.rejected,(state,action)=>{
            state.isdeleteProfileLoading = false
            state.isdeleteProfileError = true
            state.messagedeleteProfile = action.payload
        }).
        addCase(updateHoroscope.pending,(state)=>{
            state.isUpdateHoroscopeLoading = true;
        })
        .addCase(updateHoroscope.fulfilled,(state,action)=>{
            state.isUpdateHoroscopeLoading = false
            state.isUpdateHoroscopeSuccess = true
            if(action.payload.isSuccess == false)
            {
                state.isUpdateHoroscopeSuccess = false
                state.isUpdateHoroscopeProfileError = true
            }
            state.messageUpdateHoroscope = action.payload.message
        }) 
        .addCase(updateHoroscope.rejected,(state,action)=>{
            state.isUpdateHoroscopeLoading = false
            state.isUpdateHoroscopeProfileError = true
            state.messageUpdateHoroscope = action.payload
        }).addCase(setProfilePicture.pending,(state)=>{
            state.issetProfilePictureLoading = true;
        })
        .addCase(setProfilePicture.fulfilled,(state,action)=>{
            state.issetProfilePictureLoading = false
            state.issetProfilePictureSuccess = true
            if(action.payload.isSuccess == false)
            {
                state.issetProfilePictureSuccess = false
                state.issetProfilePictureError = true
            }
            state.messagesetProfile = action.payload.message
        }) 
        .addCase(setProfilePicture.rejected,(state,action)=>{
            state.issetProfilePictureLoading = false
            state.issetProfilePictureError = true
            state.messagesetProfile = action.payload
        })
        
    }    
})

export const 
   {
    resetRegisterProfile,
    resetRegisterMessages,
    resetUpdatedProfileMessage,
    resetDeleteProfileMessage,
    resetUpdatedProfile,
    resetAllProfilesByBrokerId,
    resetProfileDetailsById,
    resetDistrictsByState,
    resetImageUploadByState,
    resetProfileList,
    resetdeleteProfile,
    resetUpdateHoroscope,
    resetProfilePicture
   } = profSlice.actions
export default profSlice.reducer