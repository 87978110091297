import React, { useState, useEffect } from 'react';

export function TermsOfUse() {

// State to store the current date and time
const [TermsOfUse, setCurrentDateTime] = useState('');

// Update the time every second
useEffect(() => {
    const intervalId = setInterval(() => {
    const currentDate = new Date();
    setCurrentDateTime(currentDate.toLocaleString()); // Update the state with the current time
  }, 1000); // Update every second

  // Cleanup the interval on component unmount
  return () => clearInterval(intervalId);
}, []); // Empty array means it runs only once on mount



    return (
        <div className="row">
                <h5>TERMS AND CONDITIONS OF MEMBERSHIP IN UTTHIRA</h5>
                <p>{TermsOfUse}</p>
                 <p>
Welcome to Utthira.com
This document is an electronic record in terms of the Information Technology Act, 2000 and rules there under pertaining to electronic records as applicable and amended. This electronic record is generated by a computer system and does not require any physical or digital signatures.
This document is published in accordance with the provisions of Rule 3 (1) of the Information Technology (Intermediaries Guidelines and Digital Media Ethics Code) Rules, 2021 that require publishing the rules and regulations, privacy policy and the terms and conditions for access or usage of www.utthira.com (the "Website").
PLEASE READ THE FOLLOWING TERMS AND CONDITIONS VERY CAREFULLY BEFORE USING THE WEBSITE. ACCESSING, BROWSING OR OTHERWISE USING THE WEBSITE IMPLIES YOUR AGREEMENT TO BE BOUND BY ALL THESE TERMS AND CONDITIONS ("Agreement"). If you do not want to be bound by the Terms and Conditions, you must not use the Website or Utthira Services. The Terms and Conditions also includes the applicable policies which are incorporated herein by way of reference and as amended from time to time (the "Terms and conditions").
In these Terms, references to “You” or "Utthira registered brokers" shall mean the brokers accessing the Website/Uthhira services, its contents or using the Utthira Services offered.
Scope
Utthira acts as a platform to enable marriage brokers for seeking prospective lawful matrimonial alliances for their clients. The profiles in the database of Utthira are uploaded by the brokers.
</p>

<h6><br/><br/>1. Eligibility : </h6>
<div>A) Profiles uploaded in Utthira should be of :
<br/>I. Indian Nationals & Citizens.
<br/>II. Persons of Indian Origin (PIO).
<br/>III. Non Resident Indians (NRI).
<br/>IV. Persons of Indian Descent or Indian Heritage
<br/>V. Persons who intend to marry persons of Indian Origin.</div>
<div>B) Further the profiles uploaded should be of :
<br/>I. 18 years or above (if you are a woman) or 21 years or above (if you are a man);
<br/>II. If bride/groom have applied for Divorce, brokers may upload on our website/App by stating "Awaiting Divorce".</div>


<h6><br/><br/>2. Registration</h6>
<div>A. We expect that brokers would upload the profiles with fairness and honesty in furnishing true, accurate, current, complete information and with providing recent photos of bride/groom which will help fellow brokers to make perfect matches based on their bride/groom’s expectations.
<br/>B. If at any point of time Utthira comes to know or is so informed by third party or has reasons to believe that any information provided by you for upload (including photos) or otherwise is found to be untrue, inaccurate, or incomplete, Utthira shall have full right to suspend or terminate (without any notice) broker’s access and forfeit any amount paid by the respective broker to use the application.
<br/>C. Utthira also reserves the right to remove any profiles if the data entered are found to be faulty or irrelevant.
<br/>D. Upload of duplicate profiles of the same person is not allowed in Utthira. Utthira shall have full right to suspend or terminate (without any notice) such duplicate profile.
<br/>E. Brokers acknowledge and confirm that registration with Utthira and the usage of Utthira services is with the bonafide intention of marriage and not otherwise. If Utthira finds that if the application or the profiles being used for any other purpose other than marriage, Utthira reserves its right to initiate appropriate legal action for breach of these obligation.


<h6><br/><br/>3. Account Security</h6>
You are responsible for safeguarding the confidentiality of your Utthira login credentials such as your user id, password and for restricting access to your computer/mobile to prevent unauthorized access to your account. We, as a Company do not ask for Password and you are cautioned not to share your password to any persons. You agree to accept responsibility for all activities that occur under your account.

<h6><br/><br/>4. Role and Responsibility of Utthira</h6>
A. Utthira do not authenticate/endorse any information of any profile and hence brokers need to verify the credentials and information provided by other users
<br/>B. Utthira obligation is only to provide an interface to the brokers to upload/manage profiles from anywhere by accessing Utthira with authorised credentials. Utthira shall under no circumstances be liable or responsible to the match proposed and it solely lies on the brokers and the family of bride/groom.
<br/>C. Utthira shall safeguard sensitive user information using security standards, authentication mechanisms, access controls and encryption techniques.


<h6><br/><br/>5. Role and Responsibility of Brokers registered with Utthira</h6>
A. You, shall safeguard your profile information by creating a strong password during profile creation with combination of alphabets, both upper and lower case and numbers.
<br/>B. Any information / data required by Utthira for using the Utthira services shall be provided by the Utthira registered brokers, as and when so sought by Utthira.
<br/>C. You are requested to verify the credentials of the prospect, exercise due care and caution regarding their profile information which includes marital status, educational qualifications, financial status, occupation, character, health status, etc. Utthira shall not be liable for short coming due to any misrepresentations made by Utthira registered brokers.
<br/>D. Brokers should provide latest photograph which should not be more than 3 (three) months old while uploading the profiles. Providing old photographs/ photographs of others, inaccurate / false information shall be treated as violation of terms and conditions and Utthira shall retain their right under clause 2 (b) of this terms and conditions.
<br/>E. Utthira registered brokers are expected to disclose their health records during profile enrollment which includes any pre-existing illness, physical disability etc. Non - disclosure at the time of enrollment shall be treated as violation of the terms and conditions and Utthira shall retain their right under clause 2 (b) of this terms and conditions.
<br/>F. If Utthira registered brokers fails to update in the website, any change in the particulars furnished in their profile/ then Utthira registered brokers may be suspended by Utthira at its sole discretion.
<br/>G. In case of finalization of marriage, Utthira registered brokers should take the responsibility to delete their profile by themselves or intimate Utthira for removal/deletion of the profile.
<br/>H. Utthira registered brokers shall not probe, scan or test the vulnerabilities of the Utthira Website or any network connected to the Website nor breach the security measures instituted by Utthira.
<br/>I. Utthira registered brokers are expected to approach Utthira to resolve their complaints and grievances and shall not air their grievance/complaints in social media.


<h6><br/><br/>6. Customer Care / Customer Service</h6>
<br/>In the event you come across any concerns in our service, then please be free to contact us:
<br/>Email ID : utthira@gmail.com
<br/>Contact No : +91-8667393715


<h6><br/><br/>7. Medium of Communication to Utthira registered brokers</h6>
<br/>A. Utthira registered brokers hereby consents for receiving communication including promotional communications through any medium of communications such as electronic mails, calls, SMS or through Whatsapp messages from Utthira.
<br/>B. Utthira registered brokers confirm that the mobile number submitted to it for verification or alternative number if any provided, is not registered with the Do Not Disturb / National Customer Preference Register and they shall not initiate any complaint.


<h6><br/><br/>8. Confidentiality</h6>
Any feedback you provide to Utthira shall be deemed to be non-confidential. Utthira shall be free to use such feedback/information on an unrestricted basis. Further, by submitting the feedback, you represent and warrant that (i) your feedback does not contain confidential or proprietary information of yourself or third parties; (ii) Utthira registered brokers is not under any obligation of confidentiality, express or implied, with respect to the feedback; (iii) you are not entitled to any compensation or reimbursement of any kind from Utthira for the feedback under any circumstances.


<h6><br/><br/>9. Privacy of Membership</h6>
To protect your privacy and understand our practices as amended from time to time , please read and follow our Privacy Policy which also governs your visit to Utthira. If you object to your information being transferred or used, please do not use the website.


<h6><br/><br/>10. Content Right</h6>
A. You acknowledge that you alone are responsible and liable for the content (mandatory or optional content you provided on our website) and information including the profile details, photograph and other content(s) keyed in the relevant field of the Website/App at the time of the creation of the profile or subsequently modified by you. You hereby represent and warrant that you own all rights, title and interest in your content/information or have the necessary licenses, rights, consents, and permissions to provide the said content/information.
<br/>B. While Utthira does not accept any obligation to monitor the content/information in your profile, if the content/information is found not to be in compliance with these Terms and conditions, Utthira may delete the content/information and / or terminate or suspend the access of Utthira registered brokers without a refund of any subscription paid by you in terms of clause 2 (b) of these terms and conditions).
<br/>C. Utthira reserves the right to proceed/initiate appropriate steps under the prevailing law against infringement by violators of its proprietary rights or for illegally copying, modifying, publishing, transmitting, distributing, performing, displaying, or selling of any such proprietary information including using it for commercial purpose in any manner whatsoever.



<h6><br/><br/>11. Limitation of liability</h6>
Utthira will not be liable in case of any wrong/improper match made by the Utthira registered brokers.


<h6><br/><br/>12. Right to Delete</h6>
Not withstanding anything contained herein, Utthira reserves the absolute right to delete, in any manner as it deems fit, any content of any profile listing placed on Utthira website (once such instance come to Utthira notice) in order to ensure, that proper consent has been obtained by you , prima facie accuracy and the prevailing laws in force for the time being, especially those relating to providing any obscene, libelous, blasphemous, slanderous, defamatory or invasive of another person's (deceased or alive) right of privacy or publicity, or that may reasonably be deemed to be harmful, vulgar, pornographic, abusive, harassing, threatening, hateful, objectionable with respect to race, religion, creed, nationality, gender or otherwise unfit for reproduction; or suggests or encourages unfair or illegal /indecent, unlawful activity. Utthira also reserves the right to block/ delete / suspend the profile which might be offensive, illegal or that might violate the rights, harm or threaten the safety of Utthira & Utthira employees.



<h6><br/><br/>13. Disclaimer</h6>
A. Your access to and use of the Utthira Services or any content is at your own risk. YOU UNDERSTAND AND AGREE THAT THE BM SERVICES ARE PROVIDED TO YOU ON AN "AS IS" AND "AS AVAILABLE" BASIS. WITHOUT LIMITING THE FOREGOING, TO THE FULL EXTENT PERMITTED BY LAW, Utthira DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT. Utthira does not warrant that the Website/App, its servers, or e-mail sent from Utthira are free of viruses or other harmful components. BM will not be liable for any damages of any kind arising from the use of this Utthira site, including, but not limited to direct, indirect, incidental, punitive, and consequential damages.
<br/>B. Notwithstanding anything contrary contained anywhere, under no circumstances, BM shall be held responsible or liable whatsoever or howsoever, arising out of, relating to or connected with:
<br/>i. any untrue or incorrect information submitted by you or on your behalf;
<br/>ii. any decision taken by you or on your behalf or any consequences thereof, based on any information provided by any other user
<br/>iii. any unauthorized or illegal act done by any third party relating to or connected with any information submitted by you or on your behalf;
<br/>iv. any cyber crime attempted or committed by anyone and
<br/>v. any incident of force-majeure or 'act of god'.
<br/>vi. Any issue already stated in these terms and conditions including limitation of liability clause of these terms and conditions.
<br/>ix. BM shall not be liable for the outcome of during any interaction in a meeting, call, sms, chat, email or social media posts at any point of time
<br/>x. Any issues relating to any technical malfunction of any telecommunication network, software, hardware failures, network congestion, denial of service, or any combination of the above.
<br/>D. You expressly agree that your use of this Website/App is at your sole risk.


<h6><br/><br/>14. Termination</h6>
A. We/ Utthira in good faith reserve our right to terminate your access at our sole discretion without any notice to you and take any action as mentioned in clause 2 (b) of the Terms and Conditions, in case you
<br/>I. have provided incorrect information on our website/App or 
<br/>II. have committed any fraudulent/ unlawful/ illegal activities through the use of our website/App or
<br/>III. have violated this Terms and conditions
<br/>B. Upon termination / suspension, your right to use or access the Utthira Website services will cease immediately. All provisions of this terms and conditions that by their nature should survive termination shall survive termination, including, without limitation, disclaimers, and limitations of liability. Termination of your access to and use of the Utthira Services shall not relieve you of any obligations arising or accruing prior to such termination.


<h6><br/><br/>15. Jurisdiction and Applicable Law</h6>
A. The registration of Utthira and all Utthira services are deemed to have been entered into within the territorial Jurisdiction of Nagercoil, India.
<br/>B. The Utthira Members unconditionally agree that all such disputes and / or differences if any shall be governed by the Laws of India and submitting to the exclusive Jurisdiction of appropriate court of law in Nagercoil, Tamil Nadu, India.

<br/><br/></div>

        </div>
    );
}


export default TermsOfUse;