import axios from 'axios'

//axios.defaults.withCredentials = true
//const API = axios.create();
const bearerToken = sessionStorage.getItem("Authorization-key")
console.log('Load token ' + bearerToken)

const authAxious = axios.create({
     baseURL: 'https://matapi-gcd0ewgnddbnfdf8.southindia-01.azurewebsites.net/api',

})



authAxious.interceptors.request.use(
    function (config) {

       
        if (sessionStorage.getItem("user") && JSON.parse(sessionStorage.getItem("user"))) {
        //alert(JSON.stringify(sessionStorage.getItem("user")))
       // if (sessionStorage.getItem("Authorization-key")) {
      // alert(JSON.parse(sessionStorage.getItem("user")).token)
            config.headers = {
                //"Authorization": 'Bearer ' + sessionStorage.getItem("Authorization-key"),
               "Authorization": 'Bearer ' + JSON.parse(sessionStorage.getItem("user")).token,
            };

        }

        return config;
    },
    function (error) {
        // Do something with request error
        return Promise.reject(error)
    }
);

authAxious.interceptors.response.use(
    function (response) {
        //Any status code that lie within the range of 2xx cause this function to trigger
        //Do something with response data
        return response;
    },
    function (error) {
        //Any status code that lies outside the range of 2xx cause this function to trigger
        //Do something with response error
        return Promise.reject(error);
    }
)

// const interceptor ={
//     API,
//     authAxious,
// }

export default authAxious;
