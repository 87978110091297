import profile1 from '../../img/girlimage1.png'
import profile2 from '../../img/images3.jpeg'
export function Carousal({imageUrls})
{

//alert(parentToChild[0])
    return (
        <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
  <div className="carousel-inner">

    {  (imageUrls && imageUrls.length >0) ?
     (
     <div>
      
     {imageUrls.map((image,index)=>
(
  
  index == 0?
  (
  <div key={index} className="carousel-item active">
<img key={index} src={image}  className="d-block w-100" alt="..."></img>
</div>
):(
<div key={index} className="carousel-item">
<img key={index} src={image} className="d-block w-100" alt="..."></img>
</div>
)
)
     


     )}
     </div>)
     
     :null }

    {/* <div className="carousel-item active">
      <img src={profile1} className="d-block w-100" alt="..."></img>
    </div>
    <div className="carousel-item">
      <img src={profile2} className="d-block w-100" alt="..."></img>
    </div>
    <div className="carousel-item">
      <img src={profile1} className="d-block w-100" alt="..."></img>
    </div> */}

  </div>
  <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Previous</span>
  </button>
  <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Next</span>
  </button>
</div>
    )
}