import authAxious from '../Config/interceptor'
const API_URL_MASTER_ALL_STARS ='/master/getAllStars'
const API_URL_MASTER_ALL_RASIS ='/master/getAllRasi'
const API_URL_MASTER_ALL_DISTRICTS_BySTATE ='/master/getAllDistrictsByState'

const API_URL_MASTER_ALL_JOBS ='/master/getAllJobs'
const API_URL_MASTER_ALL_QUALIFICATIONS ='/master/getAllQualifications'
const API_URL_MASTER_ALL_RELIGIONS ='/master/getAllReligions'
const API_URL_MASTER_ALL_CASTES = '/master/getAllCaste'

const getAllStars = async() =>{
    const response = await authAxious.get(API_URL_MASTER_ALL_STARS)
    return response.data;
}
const getAllRasis = async() =>{
    const response = await authAxious.get(API_URL_MASTER_ALL_RASIS)
    return response.data;
}
const getAllDistricts = async() =>{
    const response = await authAxious.get(API_URL_MASTER_ALL_DISTRICTS_BySTATE)
    return response.data;
}

const getAllJobs = async() =>{
    const response = await authAxious.get(API_URL_MASTER_ALL_JOBS)
    return response.data;
}
const getAllQualifications = async() =>{
    const response = await authAxious.get(API_URL_MASTER_ALL_QUALIFICATIONS)
    return response.data;
}

const getAllReligions = async() =>{
    const response = await authAxious.get(API_URL_MASTER_ALL_RELIGIONS)
    return response.data;
}

const getAllCastes = async() =>{
    const response = await authAxious.get(API_URL_MASTER_ALL_CASTES)
    return response.data;
}


const masterService ={
    getAllStars,
    getAllRasis,
    getAllDistricts,
    getAllJobs,
    getAllQualifications,
    getAllReligions,
    getAllCastes
}

export default masterService 
