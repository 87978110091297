import{createSlice,createAsyncThunk} from '@reduxjs/toolkit'
import masterService  from '../Services/masterService'

const initialState ={
    masterDataList:[{"stars":[],"states":[]}],
    isMasterError:false,
    isMasterSuccess:false,
    isMasterLoading:false,
    isMastermessage:'',

    masterDataReligionList:[],
    isMasterReligionError:false,
    isMasterReligionSuccess:false,
    isMasterReligionLoading:false,


}

export const getAllStars = createAsyncThunk(
    'master/getAllStars',
    async(user,thunkAPI)=>{
        try{
             return await masterService.getAllStars()
        }
        catch(error){
            const message = (error.response && error.response.data &&
                error.response.data.message) || error.message || error.tostring()

                return thunkAPI.rejectWithValue(message)
        }
    }
)

export const getAllRasis = createAsyncThunk(
    'master/getAllRasis',
    async(user,thunkAPI)=>{
        try{
             return await masterService.getAllRasis()
        }
        catch(error){
            const message = (error.response && error.response.data &&
                error.response.data.message) || error.message || error.tostring()

                return thunkAPI.rejectWithValue(message)
        }
    }
)

export const getAllReligions = createAsyncThunk(
    'master/getAllReligions',
    async(user,thunkAPI)=>{
        try{
             return await masterService.getAllReligions();
        }
        catch(error){
            const message = (error.response && error.response.data &&
                error.response.data.message) || error.message || error.tostring()

                return thunkAPI.rejectWithValue(message)
        }
    }
)

export const masterSlice = createSlice({
    name:'master',
    initialState,
    reducers:{
        resetMaster:(state)=>{
            state.isLoading = false
            state.isError = false
            state.isSuccess = false
            state.message = ''
            state.masterDataList = [{"stars":[],"states":[]}]
        }
    },
    extraReducers:(builder)=>{
        builder
        .addCase(getAllStars.pending,(state)=>{
            state.isLoading = true;
        })
        .addCase(getAllStars.fulfilled,(state,action)=>{
            state.isLoading = false
            state.isSuccess = true
            state.masterDataList.stars = action.payload
        })
        .addCase(getAllStars.rejected,(state,action)=>{
            state.isLoading = false
            state.isError = true
            state.masterDataList.stars = []
            
        })
        .addCase(getAllReligions.pending,(state)=>{
            state.isMasterReligionLoading = true;
        })
        .addCase(getAllReligions.fulfilled,(state,action)=>{
            state.isMasterReligionLoading = false
            state.isMasterReligionSuccess = true
            state.masterDataReligionList = action.payload
        })
        .addCase(getAllReligions.rejected,(state,action)=>{
            state.isMasterReligionLoading = false
            state.isMasterReligionError = true
            state.masterDataReligionList = []
            
        })
    }    
})

export const {resetMaster} = masterSlice.actions
export default masterSlice.reducer