import authAxious from '../Config/interceptor'
import axios from 'axios'
const API_URL_REGISTER_USER ='/users'
const API_URL_GET_USERREGISTER_OTP ='/users/getNewRegisterOTP'
const API_URL_GET_USERRESETPASSWORD_OTP ='/users/getResetPasswordOTP'
const API_URL_REGISTER_LOGIN ='/users/login'
const API_URL_RESET_PASSWORD ='/users/resetUserPassword'
const API_URL_GETUSER_MENU ='/users/getUserMenuDetailsById'


const registerUser = async(userData) =>{
    const response = await authAxious.post(API_URL_REGISTER_USER,userData)
    return response.data;
}

const getResetPasswordOTP = async(data) =>{
    debugger
    const response = await authAxious.post(API_URL_GET_USERRESETPASSWORD_OTP,data)
    return response.data;
}

const getUserRegisterOTP = async(data) =>{
    const response = await authAxious.post(API_URL_GET_USERREGISTER_OTP,data)
    return response.data;
}

const loginUser = async(userData) =>{
    const response = await authAxious.post(API_URL_REGISTER_LOGIN,userData)
   // const response = await axios.post('https://matapi-gcd0ewgnddbnfdf8.southindia-01.azurewebsites.net/api/users/login',userData)
    //console.log('token...')
    //console.log(sessionStorage["Authorization-key"])
   //sessionStorage["Authorization-key"] = response.data.token;
   // debugger
   //console.log(response.data)
   debugger
    sessionStorage["user"] =JSON.stringify(response.data.data)
   // alert(sessionStorage["Authorization-key"])
    //alert(JSON.parse(sessionStorage["user"]).role)

   // console.log(sessionStorage["Authorization-key"])
    return response.data;
}

const getUserMenuDetailsById = async(data) =>{
    const response = await authAxious.post(API_URL_GETUSER_MENU)
    return response.data;
}

const resetUserPassword =  async(data) =>{
    const response = await authAxious.post(API_URL_RESET_PASSWORD,data)
    return response.data;
}

const authService ={
    registerUser,
    loginUser,
    getUserMenuDetailsById,
    getUserRegisterOTP,
    getResetPasswordOTP,
    resetUserPassword
}

export default authService 
