import { useEffect, useState } from "react";
import "../../scss/login.css"
import {loginuser, reset} from "../../Features/Slices/authSlice"
import {useDispatch, useSelector} from  'react-redux'
import { useNavigate } from 'react-router-dom'
import {toast} from "react-toastify"
import sessionData from "../../sessionData";
import { Link } from "react-router-dom";
import logo from '../../img/Utthira_logo.svg'
import loginCoverimage from '../../img/login_svg_cover.svg';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

export function Login(){
    const { executeRecaptcha } = useGoogleReCaptcha();
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const [captchaToken, setCaptchaToken] = useState('');

    const handleCaptchaChange = (token) => {
      setCaptchaToken(token); // Save the token for server validation
    };

    const [formData,setFormData] = useState({
        email:'',
        password:''
    })
     
    const {email,password} = formData
    const onchange = (e) => {
        setFormData((prevState)=>({
            ...prevState,
            [e.target.name]:e.target.value
        }))
    }

    const {user,isError,isSuccess,isLoading,message} =
    useSelector(
      (state) => state.auth
    )
    useEffect(()=>{
        if(isError){
            toast.error(message)
        }
        if(isSuccess)
        {
            console.log('broker session:'+ sessionData.getUserData().role )
            if(JSON.parse(sessionStorage["user"]).role == "Broker")
            navigate('/Dashboard', { replace: true });
            else
            navigate('/Dashboard', { replace: true });
        }

        dispatch(reset())
    },[user,isError,isSuccess,isLoading,message,navigate],dispatch)

    const onSubmit = async (e) =>{
        e.preventDefault();
        if (!executeRecaptcha) {
          toast.error("reCAPTCHA not yet loaded");
          return;
        }
        const captchaToken = await executeRecaptcha('login');
      
        setFormData((prevState)=>({
            ...prevState,
            [e.target.name]:e.target.name
        }))

        const userData ={
            email,password,captchaToken
        }

        var data = dispatch(loginuser(userData))
       // console.log(data)
        
    }
    const onRegisterClick = () =>{
        navigate('/registeruser')
    }

    const onResetUserClick = () =>{
        navigate('/ResetUser')
    }


    

    return (
        <>

<div className="container">
<div className="row">
    <div className="col-md-6">
        <div className="row">       
           <div className="app-container">
              <img src={loginCoverimage} alt="Example" className="responsive-image" />
           </div>
        </div>

        
       <div className="row">

            <div className="col-md-4">
               <img src={logo} className="login-responsive-image" alt="Love"></img>
            </div>

            <div className="col-md-8 logincompanyName">
                   Utthira Matrimony
                   <br/>
                   <div className="logincompanyTagline">
                      Find your soulmate
                      <br/>
                      <br/>
                   </div>
            </div>

          

        </div>
        
    
    </div>
  
    <div className="col-md-6 app-container">
    
    <form action="" onSubmit={onSubmit} className="form-login text-center">
        <input type="text" required className="form-control mb-3" name="email" id="email" onChange={onchange} placeholder="Enter your user name/e-mail"></input>
        <input type="password" required className="form-control mb-3" name="password" id="password" onChange={onchange} placeholder="Enter your password"></input>
        {/* <ReCAPTCHA
        sitekey="6Le1j4UqAAAAAHiW81D_l1kK-krrCPiWoU353apx" // Replace with your actual site key
        onChange={handleCaptchaChange}
      /> */}
        <button type="submit" className="btn" style={{backgroundColor:'#1aa179',color:"white"}}>Login</button>
     </form>
    
    
    </div>



</div>

    <br/>
    <br/>
    <br/>
    <br/>

</div>


        </>
    )
}